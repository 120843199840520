import React, { useEffect, useState } from "react";
import { colors } from "../../constants/colors";
import { CloseIcon } from "../../constants/icons";
import "./PopUpMessage.css";

function PopUpMessage({
  isVisible,
  setIsVisible,
  title = false,
  content = "some text",
  width = "300px",
  height = "150px",
}) {
  const onCanselHandler = () => {
    setIsVisible(false);
  };

  return (
    <div
      className="PopUpMessage-popup-wrapper"
      style={{ width: width, height: height }}
    >
      <div
        className={
          title
            ? "PopUpMessage-popup-wrapper-box"
            : "PopUpMessage-popup-wrapper-box-onlycontext"
        }
      >
        <div className="PopUpMessage-popup-close" onClick={onCanselHandler}>
          <CloseIcon width={24} height={24} color={colors.mainBg} />
        </div>
        {title && (
          <div className="PopUpMessage-popup-title">
            <div>{title}</div>
          </div>
        )}
        <div className="PopUpMessage-popup-content">
          <div>{content}</div>
        </div>
      </div>
    </div>
  );
}

export default PopUpMessage;
