import React, { useEffect, useRef, useState } from "react";
import "./LeftTitleBlock.css";
import { colors } from "../../constants/colors";
import { CloseIcon, DragReorderIcon } from "../../constants/icons";
import { useDispatch, useSelector } from "react-redux";
import { setFormToRemove } from "../../store/actions/setFormToRemove";
import { resetReducedForms } from "../../store/actions/resetReducedForms";
import { addReducedForms } from "../../store/actions/addReducedForms";
import { useScreenSize } from "../../hooks";

function LeftTitleBlock({
  headerNum,
  headerDesc,
  totalPages,
  item,
  onClick,
  setCurrentPage,
  isExpandedAll,
  isArrowsBlockClicked,
  isNarrowToggle,
  setIsArrowsBlockClicked,
  isFirstPdfBlock,
  multipleFormData,
  setMultipleFormData,
  divRef,
  onMouseDown,
  position,
  setDivRef,
  setCurrentMouseForm,
  isExpanded,
  setExpanded,
  setIsExpandedForm,
  dragging,
  isDragReorder,
  setIsDragReorder,
  isReducedTitle, setIsReducedTitle,
}) {
  const list = [];
  const screenSize = useScreenSize();
  const dispatch = useDispatch();
  // const [isDragReorder, setIsDragReorder] = useState(false);
  const wrWidth = document.querySelectorAll(".LeftTitleBlock-header-wrapper")[0]
    ?.offsetWidth;

  const { formsReduced } = useSelector((state) => state.formsReduced);

  const [isExpandedLocal, setExpandedLocal] = useState(false);

  const [isExpandedTime, setExpandedTime] = useState(false);

  // const [isReducedTitle, setIsReducedTitle] = useState(false);

  useEffect(() => {
    if (screenSize[0] > 1024 && screenSize[0] < 1325) {
      setIsReducedTitle(true);
    } else {
      setIsReducedTitle(false);
    }
  }, [screenSize[0]]);

  //NEED TO DO SOMETHING WITH QUICK CHANGING isExpandedLocal STATE ON MOVE ELEMENT

  useEffect(() => {
    const FormID = item?.formValue?.data[0]?.FormID;
    const filtered = formsReduced?.filter((f) => f.formId === FormID);
    if (filtered?.length > 0) {
      setExpandedLocal(true);
    } else {
      setExpandedLocal(false);
    }
  }, [formsReduced]);

  useEffect(() => {
    if (dragging === false) {
      const FormID = item?.formValue?.data[0]?.FormID;
      const filteredArr = formsReduced?.filter((f) => {
        return f.formId === FormID;
      });
      if (filteredArr?.length > 0) {
        // setExpandedLocal(true);
        // setExpandedItem(true);
        setIsExpandedForm({
          isExpanded: true,
          formId: FormID,
        });
        // console.log(FormID, "dragging 1", formsReduced);
      } else {
        // setExpandedLocal(false);
        // setExpandedItem(false);
        setIsExpandedForm({
          isExpanded: false,
          formId: FormID,
        });
        // console.log(dragging, "dragging 2", formsReduced);
      }
    }
  }, [dragging]);

  for (let index = 0; index < totalPages; index++) {
    list.push(index + 1);
  }

  const expandHandler = (value) => {
    // setExpanded(!isExpanded);
    const FormID = item?.formValue?.data[0]?.FormID;
    if (value) {
      // setExpandedLocal(false);
      // setExpandedItem(false);
      setIsExpandedForm({
        isExpanded: false,
        formId: FormID,
      });
    } else {
      // setExpandedLocal(true);
      // setExpandedItem(true);
      setIsExpandedForm({
        isExpanded: true,
        formId: FormID,
      });
    }
  };

  const removeSingleFormHandler = () => {
    // setMultipleFormData([
    //   ...multipleFormData.filter((el) => el.id !== item.id),
    // ]);
    dispatch(setFormToRemove(item));
  };

  useEffect(() => {
    const FormID = item?.formValue?.data[0]?.FormID;
    if (isExpandedAll) {
      dispatch(resetReducedForms());
      // setExpandedItem(false);
      setIsExpandedForm({
        isExpanded: false,
        formId: FormID,
      });
    }
    if (isExpandedAll === false) {
      dispatch(addReducedForms({ formId: FormID }));
      // setExpandedItem(true);
      setIsExpandedForm({
        isExpanded: true,
        formId: FormID,
      });
    }
  }, [isArrowsBlockClicked]);

  const ref = useRef(null);

  const onMouseDownHandler = (e) => {
    setIsDragReorder(true);
    onMouseDown(e);
    setCurrentMouseForm(item);
    setDivRef(ref);
  };

  const onMouseUpHandler = (e, item) => {
    setIsDragReorder(false);
  };

  const greyBlock = (
    <div className={"LeftTitleBlock-wrapper-gray"}>
      <div
        className={
          // isExpanded
          isExpandedLocal
            ? "LeftTitleBlock-header-wrapper LeftTitleBlock-header-wrapper-active"
            : "LeftTitleBlock-header-wrapper"
        }
      >
        <div className="LeftTitleBlock-header-left">
          <div className="LeftTitleBlock-header-exp-btn">
            {/* <div>{isExpanded ? "+" : "-"}</div> */}
            <div>{isExpandedLocal ? "+" : "-"}</div>
          </div>
          <div className="LeftTitleBlock-header-text">
            <span>{headerNum}</span>
            {isNarrowToggle && !isReducedTitle ? (
              <span className="LeftTitleBlock-header-text-desc">
                {" "}
                {headerDesc}
              </span>
            ) : null}
          </div>
          <div className="LeftTitleBlock-header-btns-wrapper">
            <div className="LeftTitleBlock-header-drag-reorder-btn">
              <DragReorderIcon />
            </div>
            <div className="LeftTitleBlock-header-exp-btn LeftTitleBlock-header-exp-btn-remove">
              <CloseIcon color={colors.red} width={10} height={10} />
            </div>
          </div>
        </div>
      </div>
      <div
        className={
          // isExpanded
          isExpandedLocal
            ? "LeftTitleBlock-main-wrapper LeftTitleBlock-main-wrapper-active"
            : "LeftTitleBlock-main-wrapper"
        }
      >
        {list &&
          list?.map((field, index) => (
            <ItemBlock
              key={index}
              item={field}
              page={{ id: item.id, page: index + 1, formNum: item.FormNum }}
              setCurrentPage={setCurrentPage}
              formIndex={index}
              isFirstPdfBlock={isFirstPdfBlock}
            />
          ))}
      </div>
    </div>
  );

  return (
    <>
      {isDragReorder && greyBlock}
      <div
        className={
          isDragReorder
            ? "LeftTitleBlock-wrapper LeftTitleBlock-header-drag-reorder-btn-draged"
            : "LeftTitleBlock-wrapper"
        }
        style={{
          left: isDragReorder ? position.x : 0,
          top: isDragReorder ? position.y : 0,
          width: isDragReorder ? wrWidth : "100%",
        }}
        // ref={divRef}
      >
        <div
          className={
            // isExpanded
            isExpandedLocal
              ? "LeftTitleBlock-header-wrapper LeftTitleBlock-header-wrapper-active"
              : "LeftTitleBlock-header-wrapper"
          }
        >
          <div className="LeftTitleBlock-header-left">
            <div
              className="LeftTitleBlock-header-exp-btn"
              onClick={() => expandHandler(isExpandedLocal)}
            >
              {/* <div>{isExpanded ? "+" : "-"}</div> */}
              <div>{isExpandedLocal ? "+" : "-"}</div>
            </div>
            <div className="LeftTitleBlock-header-text">
              <span>{headerNum}</span>
              {isNarrowToggle && !isReducedTitle ? (
                <span className="LeftTitleBlock-header-text-desc">
                  {" "}
                  {headerDesc}
                </span>
              ) : null}
            </div>
            <div className="LeftTitleBlock-header-btns-wrapper">
              <div
              // className="LeftTitleBlock-header-drag-reorder-btn"
              // onMouseDown={(e) => onMouseDownHandler(e)}
              // onMouseUp={onMouseUpHandler}
              // //for touchscreen
              // onTouchStart={(e) => onMouseDownHandler(e)}
              // // onTouchMove
              // onTouchEnd={onMouseUpHandler}
              >
                {/* <DragReorderIcon /> */}
                <DragReorderIcon color={colors.gray} />
              </div>
              <div
                className="LeftTitleBlock-header-exp-btn LeftTitleBlock-header-exp-btn-remove"
                onClick={removeSingleFormHandler}
              >
                <CloseIcon color={colors.red} width={10} height={10} />
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            // isExpanded
            isExpandedLocal
              ? "LeftTitleBlock-main-wrapper LeftTitleBlock-main-wrapper-active"
              : "LeftTitleBlock-main-wrapper"
          }
          onClick={() => onClick(item)}
        >
          {list &&
            list?.map((field, index) => (
              <ItemBlock
                key={index}
                item={field}
                page={{ id: item.id, page: index + 1, formNum: item.FormNum }}
                setCurrentPage={setCurrentPage}
                formIndex={index}
                isFirstPdfBlock={isFirstPdfBlock}
              />
            ))}
        </div>
      </div>
    </>
  );
}

export default LeftTitleBlock;

function ItemBlock({ item, page, setCurrentPage, formIndex, isFirstPdfBlock }) {
  const isClickedHandler = () => {
    setCurrentPage(page);
  };
  return (
    <div
      id={`LeftTitleBlock-list-item_${page?.formNum}-page${page.page}-id-${page.id}`.replaceAll(
        /\s/g,
        ""
      )}
      className="LeftTitleBlock-list-item"
      onClick={isClickedHandler}
      style={{
        backgroundColor:
          formIndex === 0 && isFirstPdfBlock ? colors.blue : colors.white,
        color: formIndex === 0 && isFirstPdfBlock ? colors.white : colors.blue,
      }}
    >
      <p>Page {item}</p>
    </div>
  );
}
