import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { colors } from "../../../constants/colors";
import { CloseIcon } from "../../../constants/icons";
import { useScreenSize } from "../../../hooks";
import { setRemoveAllForms } from "../../../store/actions/setAllFormsToRemove";
import { setIsOpenAllFormsConfirmPopUp } from "../../../store/actions/setIsOpenAllFormsConfirmPopUp";
import ClickOutside from "../../ClickOutside/clickoutSideComponent";
import Spiner from "../../Spiner/Spiner";
import "./baseInputList.css";

const BaseInputList = ({
  height = "40px",
  width = "100%",
  className = "base",
  id,
  label,
  required,
  disabled = false,
  placeholder,
  type = "text",
  name,
  onChange,
  onBlur,
  value,
  no_borders,
  isLoading = false,
  list,
  getList,
  defaultList,
  ref,
  setStillSendLoading,
  isAllFormsDataLoading,
  stillSendLoading,
  setMultipleFormData,
  multipleFormData = [],
  getSingleFormsData,
  initView = false,
  errorData,
  setDataError,
  selectedForms,
  setSelectedForms,
  currentList,
  setCurrentList,
  isFormsListVisible,
  setIsFormsListVisible,
  liClass = "",
  ...props
}) => {
  const dispatch = useDispatch();
  const { removeAllForms } = useSelector((state) => state.allFormsToRemove);
  // const [currentList, setCurrentList] = useState([]);
  // const [isFormsListVisible, setIsFormsListVisible] = useState(false);
  // const [selectedForms, setSelectedForms] = useState([]);
  const [selectedList, setSelectedList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [wrapperWidth, setWrapperWidth] = useState(null);

  const [firstData, setFirstData] = useState(0);

  const inputDiv = document.getElementById("MainPage-header-left-Search-input");
  const screenSize = useScreenSize();

  useEffect(() => {
    let headerWrappeWidth = document.getElementById(
      "MainPage-header-wrapper"
    )?.offsetWidth;
    setWrapperWidth(headerWrappeWidth - 48);
  }, [screenSize[0]]);

  useEffect(() => {
    setFirstData((f) => f + 1);
    if (list?.length > 0) {
      if (selectedList?.length > 0) {
        const filteredItems = selectedList.filter(
          (item) => !list.some((exclusion) => exclusion.id === item.id)
        );
        setSelectedList([...filteredItems, ...list]);
      } else {
        setSelectedList([...list]);
      }
    }
  }, [list]);

  useEffect(() => {
    if (list?.length > 0) {
      let filteredArray = list.filter(function (array_el) {
        return (
          multipleFormData.filter(function (anotherOne_el) {
            return anotherOne_el.id == array_el.id;
          }).length == 0
        );
      });
      setCurrentList([...filteredArray]);
      // setSelectedForms([]);
    } else {
      setCurrentList([]);
      // setSelectedForms([]);
    }
  }, [list]);

  const clickOutsideHandler = () => {
    setIsFormsListVisible(false);
    //setSelectedForms([]); //new
    setDataError(null);
    setSearchValue("");
    setSelectedList([]);
  };

  const clickInputHandler = () => {
    if (!isFormsListVisible && !list.length > 0) getList();
    setIsFormsListVisible(true);
  };

  const onFormClickHandler = (form) => {
    setTimeout(
      () => setCurrentList([...currentList.filter((el) => el.id !== form.id)]),
      0
    );
    let temp = selectedForms.filter((f) => f.id === form.id);
    if (temp.length === 0) setSelectedForms([...selectedForms, form]);
  };

  const clearInputField = () => {
    setSearchValue("");
  };

  const clickRemoveHandler = () => {
    dispatch(setIsOpenAllFormsConfirmPopUp(true));
  };

  useEffect(() => {
    if (removeAllForms) {
      setCurrentList([]);
      setMultipleFormData([]);
      setSelectedForms([]);
      setIsFormsListVisible(false);
      setDataError(null);
      setSearchValue("");
      dispatch(setIsOpenAllFormsConfirmPopUp(null));
      dispatch(setRemoveAllForms(null));
    }
  }, [removeAllForms]);

  const clickSendHandler = () => {
    if (selectedForms?.length > 0) {
      setStillSendLoading(true);
      const processItems = async () => {
        const results = await Promise.all(
          // temp.map(async (m) => getSingleFormsData(m.id)) //new
          selectedForms.map(async (m) => getSingleFormsData(m.id))
        );
        setStillSendLoading(false);
        setMultipleFormData(
          [...multipleFormData, ...results].sort(
            (a, b) =>
              // a.FormName.localeCompare(b.FormName)
              a?.bounds?.boundY - b?.bounds?.boundY
          )
        );
      };

      processItems();

      setSelectedForms([]);
      clearInputField();
    }
  };

  const removeSelectedItem = (data) => {
    if (data?.id) {
      setTimeout(
        () =>
          setSelectedForms([
            ...selectedForms.filter((el) => el.id !== data.id),
          ]),
        0
      );

      setMultipleFormData(
        [...multipleFormData]
          .filter((m) => data?.id !== m.id)
          .sort(
            (a, b) =>
              // a.FormName.localeCompare(b.FormName)
              a?.bounds?.boundY - b?.bounds?.boundY
          )
      );

      setCurrentList([...currentList, data]);
    }
  };

  useEffect(() => {
    if (searchValue !== "") {
      setCurrentList(
        [
          ...list.filter((item) => {
            if (
              item?.FormName.toLowerCase().includes(
                searchValue.toLowerCase()
              ) ||
              item?.FormNum.toLowerCase().includes(searchValue.toLowerCase())
            ) {
              return true;
            }
          }),
        ].sort((a, b) =>
          a.FormName.toLowerCase().localeCompare(b.FormName.toLowerCase())
        )
      );
    } else if (searchValue === "" && list?.length > 0) {
      setCurrentList([...list]);
    }
  }, [searchValue]);

  // useEffect(() => {
  //   if (searchValue === "") {
  //     getList();
  //     console.log(list, "listtt", defaultList);
  //   }
  // }, [searchValue]);

  useEffect(() => {
    if (selectedForms?.length > 0) {
      setStillSendLoading(true);
      const processItems = async () => {
        let results = await Promise.all(
          // temp.map(async (m) => getSingleFormsData(m.id)) //new
          selectedForms.map(async (m) => getSingleFormsData(m.id))
        );

        setStillSendLoading(false);
        setMultipleFormData(
          [...multipleFormData, ...results]
            .sort(
              (a, b) =>
                // a.FormName.localeCompare(b.FormName)
                a?.id - b?.id
            )
            .filter((m, i, arr_m) => {
              if (i !== arr_m?.length - 1) {
                if (m.id !== arr_m[i + 1].id) {
                  return true;
                } else {
                  return false;
                }
              } else {
                return true;
              }
            })
            .sort(
              (a, b) =>
                // a.FormName.localeCompare(b.FormName)
                a?.bounds?.boundY - b?.bounds?.boundY
            )
        );
      };
      processItems();
    }

    if (selectedForms?.length === 0) {
      getList();
    }
  }, [selectedForms]);

  useEffect(() => {
    if (isFormsListVisible && currentList?.length > 0 && isLoading === false) {
      inputDiv.focus();
    }
  }, [isFormsListVisible, currentList?.length, isLoading]);

  const inputChangeHandler = (e) => {
    setSearchValue(e.target.value);
    e.preventDefault();
  };

  return (
    <div
      className={`base-input-container base-input-container-${className}  ${
        required ? "base-input-block-required" : "base-input-block-default"
      } ${no_borders ? "base-input-container-line" : ""}`}
    >
      {/* {isFormsListVisible && currentList?.length > 0 && isLoading === false && (
        <div
          className="baseInput-ul-wrapper-close"
          onClick={clickOutsideHandler}
          style={{ top: selectedForms?.length > 0 ? "36px" : "48px" }}
        >
          <div className="baseInput-ul-wrapper-close-box">
            <CloseIcon color={colors.mainBg} width={18} height={18} />
          </div>
        </div>
      )} */}
      {label && (
        <div>
          <label htmlFor={id} className={"base-input-label"}>
            {label}
          </label>
        </div>
      )}
      <div
        // TODO TEMPORARY REMOVED FUNC
        // className={
        //   selectedForms?.length > 0 &&
        //   !stillSendLoading &&
        //   !isAllFormsDataLoading
        //     ? "baseInput-Send-Btn-wrapper baseInput-Send-Btn-wrapper-active"
        //     : "baseInput-Send-Btn-wrapper baseInput-Send-Btn-wrapper-disabled"
        // }

        className={
          currentList?.length === 1
            ? "baseInput-Send-Btn-wrapper baseInput-Send-Btn-wrapper-active"
            : "baseInput-Send-Btn-wrapper baseInput-Send-Btn-wrapper-disabled"
        }

        // TODO TEMPORARY REMOVED FUNC
        // onClick={
        //   !stillSendLoading && !isAllFormsDataLoading ? clickSendHandler : null
        // }
      >
        Add
      </div>
      <div
        className={
          (multipleFormData?.length > 0 &&
            !stillSendLoading &&
            !isAllFormsDataLoading) ||
          (initView && selectedForms?.length > 0) //new
            ? "baseInput-Remove-Btn-wrapper baseInput-Remove-Btn-wrapper-active"
            : "baseInput-Remove-Btn-wrapper baseInput-Remove-Btn-wrapper-disabled"
        }
        onClick={
          (multipleFormData?.length > 0 &&
            !stillSendLoading &&
            !isAllFormsDataLoading) ||
          (initView && selectedForms?.length > 0) //new
            ? clickRemoveHandler
            : null
        }
      >
        <CloseIcon
          color={multipleFormData?.length > 0 ? colors.red : colors.gray}
          width={10}
          height={10}
        />{" "}
        All
      </div>
      <ClickOutside
        isLoading={isLoading === true}
        onClick={clickOutsideHandler}
      >
        <div
          className={`base-input-block ${
            required ? "base-input-block-required" : ""
          }`}
          onClick={clickInputHandler}
        >
          <input
            style={{ width: width, height: height }}
            // eslint-disable-next-line no-undef
            onChange={inputChangeHandler}
            onBlur={onBlur}
            ref={ref}
            disabled={disabled}
            placeholder={placeholder}
            id={id}
            required={required}
            className={className}
            type={type}
            name={name}
            value={searchValue}
            autoComplete="off"
            {...props}
          />
        </div>
        {/* {isFormsListVisible && selectedForms?.length > 0 && (
             <div className="baseInput-selected-wrapper-tooltip">
            {isLoading === false && (
              <div>
                Please, Click <span>Add</span> Button To Confirm
              </div>
            )}
          </div>
        )} */}
        {!errorData && isFormsListVisible && (
          <div
            className={
              initView
                ? "baseInput-selected-wrapper baseInput-selected-wrapper-init"
                : "baseInput-selected-wrapper baseInput-selected-wrapper-main"
            }
            style={{
              width: wrapperWidth ? `${wrapperWidth}px` : "100%",
              // paddingTop: selectedForms?.length > 0 ? "22px" : "8px",
              paddingTop: "8px",
            }}
          >
            {selectedForms.length > 0 &&
              isLoading === false &&
              selectedForms
                .sort((a, b) => a.id - b.id)
                .map((el, i) => {
                  return (
                    <div key={i} className="baseInput-selected-wrapper-el">
                      <div className="baseInput-selected-wrapper-el-title">
                        {el.FormNum}
                      </div>
                      <div
                        className="baseInput-selected-wrapper-el-close"
                        onClick={() => removeSelectedItem(el)}
                      >
                        <CloseIcon height={10} width={10} color={colors.blue} />
                      </div>
                    </div>
                  );
                })}

            {(isLoading === true || stillSendLoading) && (
              // {stillSendLoading && (
              <div className="baseInput-ul-wrapper-spiner">
                <Spiner />
              </div>
            )}

            <div
              className="baseInput-ul-wrapper"
              style={{
                display:
                  isFormsListVisible &&
                  currentList?.length > 0 &&
                  isLoading === false
                    ? "block"
                    : "none",
                border:
                  isLoading === true
                    ? `1px solid ${colors.gray}`
                    : "auto"
                    ? `1px solid ${colors.gray}`
                    : "none",
                width: "100%",
                maxHeight: isLoading ? "90px" : "320px",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              {/* <div
                className="baseInput-ul-wrapper-close"
                onClick={clickOutsideHandler}
              >
                <div className="baseInput-ul-wrapper-close-box">
                  <CloseIcon color={colors.mainBg} width={18} height={18} />
                </div>
              </div> */}
              <ul>
                {isLoading === false &&
                  currentList
                    .sort((a, b) =>
                      a.FormName.toLowerCase().localeCompare(
                        b.FormName.toLowerCase()
                      )
                    )
                    .map((form, i) => {
                      return (
                        <li
                          key={i}
                          onClick={(e) => {
                            onFormClickHandler(form);
                          }}
                          className={
                            liClass
                              ? `liClass-${form.id} ${liClass}`
                              : `liClass-${form.id}`
                          }
                        >
                          <p>
                            {form.FormNum} {form.FormName}
                            {/* {" "}
                            {form.revisiondate
                              ? `- ${form.revisiondate}`
                              : null} */}
                          </p>
                        </li>
                      );
                    })}
              </ul>
            </div>
          </div>
        )}
        {errorData && (
          <div
            className={
              initView
                ? "baseInput-error-wrapper baseInput-error-wrapper-init"
                : "baseInput-error-wrapper baseInput-error-wrapper-main"
            }
          >
            {" "}
            {errorData?.message}
          </div>
        )}
        {currentList?.length === 0 &&
          isLoading !== true &&
          !stillSendLoading &&
          firstData > 1 &&
          selectedForms?.length === 0 &&
          isFormsListVisible && (
            <div
              className={
                initView
                  ? "baseInput-no-result-wrapper baseInput-no-result-wrapper-init"
                  : "baseInput-no-result-wrapper baseInput-no-result-wrapper-main"
              }
            >
              No Result Found
            </div>
          )}
        {/* {currentList?.length === 0 &&
          isLoading !== true &&
          !stillSendLoading &&
          firstData > 1 &&
          selectedForms?.length === 0 &&
          isFormsListVisible && (
            <div
              className="baseInput-ul-wrapper-close-no-result"
              onClick={clickOutsideHandler}
            >
              <div className="baseInput-ul-wrapper-close-box">
                <CloseIcon color={colors.mainBg} width={18} height={18} />
              </div>
            </div>
          )} */}
      </ClickOutside>
    </div>
  );
};

export default BaseInputList;
