import React from "react";
import { Login } from "../../components";
import "./LoginForm.css";

function LoginForm() {
  return (
    <div className="LoginForm-wrapper">
      <Login />
    </div>
  );
}

export default LoginForm;
