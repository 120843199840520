import React, { Fragment, useEffect, useState } from "react";
import { colors } from "../../constants/colors";
import { CheckBoxActiveIcon, CheckBoxIcon } from "../../constants/icons";
import { addAllWorkFormsData } from "../../store/actions/addAllWorkFormsData";
import { addFormsData } from "../../store/actions/addFormsData";
import { removeAllWorkFormsData } from "../../store/actions/removeAllWorkFormsData";
import { removeFormsData } from "../../store/actions/removeFormsData";
import { setIsDatePickerOpened } from "../../store/actions/setIsDatePickerOpened";
import { setIsFormOpened } from "../../store/actions/setIsFormOpened";
import TooltipMessage from "../TooltipMessage/tooltipMessage";
import { getTextWidth } from "../../utils/getTextWidth";
import "./FieldBlock.css";

function FieldBlock({
  doc,
  pageNumber,
  field,
  fieldType,
  params,
  dispatch,
  isPopUp,
  setCurrentFieldData,
  mappingid,
  allFromData,
  formDataObj,
  setIsCheckboxChecked,
  isCheckboxChecked
}) {
  const [isChecked, setIsChecked] = useState(null);
  const [currentData, setCurrentData] = useState(null);
  const [tapCount, setTapCount] = useState(0);

  let currentObj = doc?.formValue?.data?.filter(
    (f) => f?.FormValuesID === field?.FormValuesID
  )[0];

  const currentCheckboxDataObj = {
    FormID: currentObj?.FormID,
    ContractDataID: null,
    FormValuesID: currentObj?.FormValuesID,
    Value: isChecked,
    Value_f2: null,
    Value_f3: null,
    Value_f4: null,
    Value_f5: null,
    FieldType: currentObj?.f_fieldtype,
    special_action: null, // null if none special action is true
  };

  useEffect(() => {
    if (isChecked) {
      setCurrentData(currentCheckboxDataObj);
      dispatch(addFormsData(currentCheckboxDataObj));
    }
    if (isChecked === false) {
      setCurrentData(null);
      if (currentCheckboxDataObj.ContractDataID === null) {
        dispatch(removeFormsData(currentCheckboxDataObj));
      } else {
        dispatch(addFormsData(currentCheckboxDataObj));
      }
    }
  }, [isChecked]);

  const onClickHandler = () => {
    if (fieldType === "checkbox") {
      setIsChecked(!isChecked);
      setIsCheckboxChecked(!isCheckboxChecked)
    }
    console.log(currentObj, "-----");
  };

  const onDoubleClickHandler = () => {
    // if (fieldType !== "checkbox" && fieldType !== "datetime" && isPopUp) {
    if (fieldType !== "checkbox" && isPopUp) {
      setCurrentFieldData({
        all_data: currentObj,
        field: currentObj?.formValuesDisplayed?.data,
      });
      dispatch(setIsFormOpened(true));
    }
    if (fieldType === "datetime" && isPopUp) {
      dispatch(setIsDatePickerOpened(true));
    }
  };

  const handleClick = (e) => {
    switch (e.detail) {
      case 1:
        onClickHandler();
        break;
      case 2:
        onDoubleClickHandler();
        break;
      case 3:
        console.log("triple click");
        break;
    }
  };

  const handleTouchStart = () => {
    setTapCount((prevCount) => prevCount + 1);
    setTimeout(() => setTapCount(0), 500);
    if (tapCount === 1) {
      // Double-tap detected
      console.log("Double-tap detected");
      // Run your double-tap handling logic here
      onDoubleClickHandler();
      setTapCount(0);
    }
  };

  useEffect(() => {
    const checkBoxDiv = document.getElementById(
      `fieldBlock-${currentObj?.FormValuesID}`
    );
    if (
      checkBoxDiv?.getAttribute("checked") === "true" &&
      fieldType === "checkbox"
    ) {
      setIsChecked(true);
    }
  }, []);

  return (
    <>
      <div
        className="field-wrapper-1"
        onClick={onClickHandler}
        onTouchStart={handleTouchStart}
        onDoubleClick={onDoubleClickHandler}
        // onClick={handleClick}
        style={{
          backgroundColor: isPopUp ? colors.lightBlueBg : colors.yellowBg,
          // width: "100%",
          // height: 15,
        }}
      >
        {fieldType === "checkbox" && (
          <CheckBoxField
            params={params}
            isChecked={isChecked}
            currentObj={currentObj}
            field={field}
          />
        )}
        {(fieldType === "textfield" || fieldType === "datetime") && isPopUp && (
          <PopUpField
            params={params}
            field={field}
            mappingid={mappingid}
            formDataObj={formDataObj}
            obj={{
              all_data: currentObj,
              field: currentObj?.formValuesDisplayed?.data,
            }}
          />
        )}
        {fieldType === "textarea" && !isPopUp && (
          <TextAreaField
            params={params}
            field={field}
            mappingid={mappingid}
            obj={{
              all_data: currentObj,
              field: currentObj?.formValuesDisplayed?.data,
            }}
            dispatch={dispatch}
            formDataObj={formDataObj}
          />
        )}
        {(fieldType === "textfield" || fieldType === "datetime") &&
          !isPopUp && (
            <TextFieldInput
              params={params}
              field={field}
              mappingid={mappingid}
              obj={{
                all_data: currentObj,
                field: currentObj?.formValuesDisplayed?.data,
              }}
              allFromData={allFromData}
              dispatch={dispatch}
              formDataObj={formDataObj}
            />
          )}
        {fieldType === "currency" && !isPopUp && (
          <InputField
            params={params}
            field={field}
            mappingid={mappingid}
            obj={{
              all_data: currentObj,
              field: currentObj?.formValuesDisplayed?.data,
            }}
            dispatch={dispatch}
            formDataObj={formDataObj}
          />
        )}
        {fieldType === "number" && !isPopUp && (
          <InputField
            params={params}
            field={field}
            mappingid={mappingid}
            obj={{
              all_data: currentObj,
              field: currentObj?.formValuesDisplayed?.data,
            }}
            dispatch={dispatch}
            formDataObj={formDataObj}
          />
        )}
      </div>
      {fieldType === "textfield" && isPopUp && (
        <div className="field-tooltip-wrapper">
          <TooltipMessage content="Double Click" />
        </div>
      )}
    </>
  );
}

export default FieldBlock;

const PopUpField = ({ params, field, mappingid, obj, formDataObj }) => {
  const [value, setValue] = useState("");

  useEffect(() => {
    if (formDataObj?.length > 0) {
      formDataObj?.map((saved_obj) => {
        if (
          saved_obj.FieldType === "textfield" &&
          saved_obj.FormValuesID === field.FormValuesID &&
          obj?.all_data?.f_popup
          // && saved_obj.FormID === obj.all_data.FormID
        ) {
          let tempValue = "";

          if (field.Display_f_value) {
            tempValue = saved_obj?.Value;
          }

          if (field.Display_f2_value) {
            tempValue = tempValue + " " + saved_obj.Value_f2;
          }
          if (field.Display_f3_value) {
            tempValue = tempValue + " " + saved_obj.Value_f3;
          }
          if (field.Display_f4_value) {
            tempValue = tempValue + " " + saved_obj.Value_f4;
          }
          if (field.Display_f5_value) {
            tempValue = tempValue + " " + saved_obj.Value_f5;
          }
          if (field.Display_All) {
            tempValue = toAddressFormatt(
              saved_obj?.Value,
              saved_obj.Value_f2,
              saved_obj.Value_f3,
              saved_obj.Value_f4,
              saved_obj.Value_f5
            );
          }
          // console.log(tempValue, "tempValuetempValue");
          setValue(tempValue);
        }
      });
    }
  }, []);

  let judgeAl = field?.txtjustified;
  if (judgeAl === "l") judgeAl = "fieldBlock-text-content-j-l";
  if (judgeAl === "r") judgeAl = "fieldBlock-text-content-j-r";
  if (judgeAl === "c") judgeAl = "fieldBlock-text-content-j-c";

  return (
    <div
      id={`fieldBlock-text-content-${field?.FormValuesID}-displayed-${field?.FormValuesDisplayedID}`}
      style={{
        fontSize: `${field?.fontsize}pt`,
        color: colors.textBlue,
        // backgroundColor: colors.lightBlueBg,
        height: params.height,
        lineHeight: `${field?.fontsize}pt`,
      }}
      className={
        mappingid
          ? `fieldBlock-text-content fieldBlock-text-content-mappingid-${mappingid} ${judgeAl}`
          : `fieldBlock-text-content ${judgeAl}`
      }
    >
      {value}
    </div>
  );
};

const CheckBoxField = ({ params, isChecked, currentObj, field }) => {
  return (
    <div
      id={`fieldBlock-checkbox-content-${field?.FormValuesID}-displayed-${field?.FormValuesDisplayedID}`}
      style={{ position: "relative" }}
      className="field-block-checkbox FieldBlock-fieldBlock-input-content"
      // id={`field-block-checkbox-${currentObj.FormID}-FormValuesID-${currentObj.FormValuesID}`}
    >
      {isChecked ? (
        <CheckBoxActiveIcon
          width={params.width + 4}
          height={params.width + 4}
          // color={colors.textBlue}
        />
      ) : (
        <CheckBoxIcon
          width={params.width + 4}
          height={params.width + 4}
          // color={colors.textBlue}
        />
      )}
    </div>
  );
};

const TextFieldInput = ({
  params,
  field,
  mappingid = "none",
  dispatch,
  obj,
  allFromData,
  formDataObj,
  multipleFormData,
}) => {
  const [value, setValue] = useState("");
  const [formData, setFormData] = useState(null);
  const [allFormData, setAllFormData] = useState(null);

  const [pageValueArray, setPageValueArray] = useState([]);

  // const tmp = allFromData.filter(f=>f.FormID === obj.all_data.FormID && f.f_fieldtype === "textfield" && !f.f_popup && f.formValuesDisplayed.data.some((t, ind, t_arr)=>t?.next_line === true))

  let judgeAl = field?.txtjustified;
  if (judgeAl === "l") judgeAl = "fieldBlock-text-content-j-l";
  if (judgeAl === "r") judgeAl = "fieldBlock-text-content-j-r";
  if (judgeAl === "c") judgeAl = "fieldBlock-text-content-j-c";

  useEffect(() => {
    const tmp = allFromData.filter(
      (f) =>
        f.FormID === obj.all_data.FormID &&
        (f.f_fieldtype === "textfield" || f.f_fieldtype === "datetime") &&
        !f.f_popup
      // && f.formValuesDisplayed.data.some(
      //   (t, ind, t_arr) => t?.next_line === true
      // )
    );
    if (tmp.length > 0) {
      let k = [];
      tmp.map((t) =>
        t.formValuesDisplayed?.data?.map((p) =>
          k.push({ ...p, FormID: t.FormID })
        )
      );
      setPageValueArray(
        [...k].sort(function (a, b) {
          // return parseFloat(a.UserSpaceY) - parseFloat(b.UserSpaceY);
          return a?.bounds?.boundY - b?.bounds?.boundY;
        })
      );
    }
  }, []);

  useEffect(() => {
    if (formDataObj?.length > 0) {
      formDataObj?.map((saved_obj) => {
        if (
          (saved_obj.FieldType === "textfield" ||
            saved_obj.FieldType === "datetime") &&
          saved_obj.FormValuesID === obj.all_data.FormValuesID &&
          saved_obj.FormID === obj.all_data.FormID
        ) {
          setValue(saved_obj.Value);
        }
      });
    }
  }, []);

  const onSubmitHandler = (value) => {
    setFormData({
      FormID: obj.all_data.FormID,
      ContractDataID: null,
      FormValuesID: obj.all_data.FormValuesID,
      Value: value !== "" ? value : null,
      Value_f2: null,
      Value_f3: null,
      Value_f4: null,
      Value_f5: null,
      FieldType: obj.all_data.f_fieldtype,
      special_action: null,
    });
    setAllFormData({
      FormID: obj.all_data.FormID,
      ContractDataID: null,
      FormValuesID: obj.all_data.FormValuesID,
      Value: value ? value : null,
      Value_f2: null,
      Value_f3: null,
      Value_f4: null,
      Value_f5: null,
      FieldType: obj.all_data.f_fieldtype,
      fieldData: obj.field,
      special_action: null,
      mappingid: obj?.all_data?.mappingid,
    });
  };

  useEffect(() => {
    if (formData?.FieldType && allFormData?.FieldType) {
      const delayDebounceFn = setTimeout(() => {
        if (formData?.Value !== "remove") {
          dispatch(addFormsData(formData));
          dispatch(addAllWorkFormsData(allFormData));
        } else {
          dispatch(removeFormsData(formData));
          dispatch(removeAllWorkFormsData(allFormData));
        }
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [formData, value]);

  const onChangeHandler = (e) => {
    let str = e.target.value;
    const wrapperLength = field?.WidthX.replace(/[^0-9.]/g, "");
    const strLength = getTextWidth(str, field?.fontsize);
    if (e.target.value === "" && value.length > 0) {
      setValue("");
      onSubmitHandler("remove");
    }

    if (str !== "") {
      setValue(str);
      const tempCurrent = [];
      const tempNext = [];
      if (strLength * 1.05 >= +wrapperLength && field?.next_line === true) {
        pageValueArray?.map((pv, i, pv_arr) => {
          if (pv.FormValuesID === obj.all_data.FormValuesID) {
            tempNext.push(pv_arr[i + 1]);
            tempCurrent.push(pv_arr[i]);
          }
        });
        const inputDivCurrent = document
          .getElementById(
            `fieldBlock-input-content-${tempCurrent[0]?.FormValuesID}-displayed-${tempCurrent[0]?.FormValuesDisplayedID}`
          )
          ?.querySelectorAll(".fieldBlock-input-content")[0];
        const inputDivNext = document
          .getElementById(
            `fieldBlock-input-content-${tempNext[0]?.FormValuesID}-displayed-${tempNext[0]?.FormValuesDisplayedID}`
          )
          ?.querySelectorAll(".fieldBlock-input-content")[0];
        inputDivNext?.focus();
      }
      // if (field?.next_line === true) {
      //   const inputDivCurrent = document
      //     .getElementById(
      //       `fieldBlock-input-content-${tempCurrent[0]?.FormValuesID}-displayed-${tempCurrent[0]?.FormValuesDisplayedID}`
      //     )
      //     ?.querySelectorAll(".fieldBlock-input-content")[0];
      //   const inputDivNext = document
      //     .getElementById(
      //       `fieldBlock-input-content-${tempNext[0]?.FormValuesID}-displayed-${tempNext[0]?.FormValuesDisplayedID}`
      //     )
      //     ?.querySelectorAll(".fieldBlock-input-content")[0];
      //   if (inputDivCurrent && inputDivNext) {
      //     // onSubmitHandler(str);
      //   }
      // }

      if (
        field?.next_line !== true &&
        field?.FormValuesID === tempNext[0]?.FormValuesID
      ) {
        const inputDivCurrent = document
          .getElementById(
            `fieldBlock-input-content-${tempCurrent[0]?.FormValuesID}-displayed-${tempCurrent[0]?.FormValuesDisplayedID}`
          )
          ?.querySelectorAll(".fieldBlock-input-content")[0];
        const inputDivNext = document
          .getElementById(
            `fieldBlock-input-content-${tempNext[0]?.FormValuesID}-displayed-${tempNext[0]?.FormValuesDisplayedID}`
          )
          ?.querySelectorAll(".fieldBlock-input-content")[0];
        if (inputDivCurrent && inputDivNext) {
          onSubmitHandler(inputDivCurrent.value + inputDivNext.value);
        }
      } else {
        onSubmitHandler(str);
      }
      // if (
      //   field?.next_line !== true
      // ) {
      //   onSubmitHandler(str);
      // }
    }
  };

  return (
    <div
      id={`fieldBlock-input-content-${field?.FormValuesID}-displayed-${field?.FormValuesDisplayedID}`}
      style={{
        // fontSize: `${field?.fontsize}pt`,
        color: colors.textBlue,
        // backgroundColor: colors.green,
        height: params.height,
        lineHeight: `${field?.fontsize}pt`,
      }}
      className="FieldBlock-fieldBlock-input-content"
    >
      <input
        style={{ fontSize: `${field?.fontsize}pt` }}
        className={
          mappingid
            ? `fieldBlock-input-content fieldBlock-input-content-mappingid-${mappingid} ${judgeAl}`
            : `fieldBlock-input-content ${judgeAl}`
        }
        value={value}
        onChange={onChangeHandler}
      />
    </div>
  );
};

const InputField = ({
  params,
  field,
  mappingid = "none",
  dispatch,
  obj,
  formDataObj,
  multipleFormData,
}) => {
  const [value, setValue] = useState("");
  const [formData, setFormData] = useState(null);
  const [allFormData, setAllFormData] = useState(null);

  useEffect(() => {
    if (formDataObj?.length > 0) {
      formDataObj?.map((saved_obj) => {
        if (
          (saved_obj.FieldType === "currency" ||
            saved_obj.FieldType === "number") &&
          saved_obj.FormValuesID === obj.all_data.FormValuesID &&
          saved_obj.FormID === obj.all_data.FormID
        ) {
          setValue(saved_obj.Value);
        }
      });
    }
  }, []);

  const onSubmitHandler = (value) => {
    setFormData({
      FormID: obj.all_data.FormID,
      ContractDataID: null,
      FormValuesID: obj.all_data.FormValuesID,
      Value: value !== "" ? value : null,
      Value_f2: null,
      Value_f3: null,
      Value_f4: null,
      Value_f5: null,
      FieldType: obj.all_data.f_fieldtype,
      special_action: null,
    });
    setAllFormData({
      FormID: obj.all_data.FormID,
      ContractDataID: null,
      FormValuesID: obj.all_data.FormValuesID,
      Value: value ? value : null,
      Value_f2: null,
      Value_f3: null,
      Value_f4: null,
      Value_f5: null,
      FieldType: obj.all_data.f_fieldtype,
      fieldData: obj.field,
      special_action: null,
      mappingid: obj?.all_data?.mappingid,
    });
  };

  useEffect(() => {
    if (formData?.FieldType && allFormData?.FieldType) {
      const delayDebounceFn = setTimeout(() => {
        if (value !== "") {
          dispatch(addFormsData(formData));
          dispatch(addAllWorkFormsData(allFormData));
        } else {
          dispatch(removeFormsData(formData));
          dispatch(removeAllWorkFormsData(allFormData));
        }
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [formData, value]);

  const onChangeHandler = (e) => {
    let str = e.target.value.replace(/\D/g, "");
    if (str === "") {
      setValue("");
    }
    if (str !== "") {
      let number = +str;
      let result = number.toLocaleString("en-US");
      setValue(result);
      // onSubmitHandler(result.replace(/\D/g, ''))
      onSubmitHandler(result);
    }
  };

  return (
    <div
      id={`fieldBlock-input-content-${field?.FormValuesID}-displayed-${field?.FormValuesDisplayedID}`}
      style={{
        fontSize: `${field?.fontsize}pt`,
        color: colors.textBlue,
        // backgroundColor: colors.lightBlueBg,
        height: params.height,
        lineHeight: `${field?.fontsize}pt`,
      }}
      className="FieldBlock-fieldBlock-input-content"
      onClick={() => console.log(formDataObj, "saved_obj.Value")}
    >
      <input
        style={{ fontSize: `${field?.fontsize}pt` }}
        className={
          mappingid
            ? `fieldBlock-input-content fieldBlock-input-content-mappingid-${mappingid}`
            : "fieldBlock-input-content"
        }
        value={value}
        onChange={onChangeHandler}
      />
    </div>
  );
};

const TextAreaField = ({
  params,
  field,
  mappingid = "none",
  dispatch,
  obj,
  formDataObj,
  multipleFormData,
}) => {
  const [value, setValue] = useState("");
  const [formData, setFormData] = useState(null);
  const [allFormData, setAllFormData] = useState(null);

  useEffect(() => {
    if (formDataObj?.length > 0) {
      formDataObj?.map((saved_obj) => {
        if (
          saved_obj.FieldType === "textarea" &&
          saved_obj.FormValuesID === obj.all_data.FormValuesID &&
          saved_obj.FormID === obj.all_data.FormID
        ) {
          setValue(saved_obj.Value);
        }
      });
    }
  }, []);

  const onSubmitHandler = (value) => {
    setFormData({
      FormID: obj.all_data.FormID,
      ContractDataID: null,
      FormValuesID: obj.all_data.FormValuesID,
      Value: value !== "" ? value : null,
      Value_f2: null,
      Value_f3: null,
      Value_f4: null,
      Value_f5: null,
      FieldType: obj.all_data.f_fieldtype,
      special_action: null,
    });
    setAllFormData({
      FormID: obj.all_data.FormID,
      ContractDataID: null,
      FormValuesID: obj.all_data.FormValuesID,
      Value: value ? value : null,
      Value_f2: null,
      Value_f3: null,
      Value_f4: null,
      Value_f5: null,
      FieldType: obj.all_data.f_fieldtype,
      fieldData: obj.field,
      special_action: null,
      mappingid: obj?.all_data?.mappingid,
    });
  };

  useEffect(() => {
    if (formData?.FieldType && allFormData?.FieldType) {
      const delayDebounceFn = setTimeout(() => {
        if (value !== "") {
          dispatch(addFormsData(formData));
          dispatch(addAllWorkFormsData(allFormData));
        } else {
          dispatch(removeFormsData(formData));
          dispatch(removeAllWorkFormsData(allFormData));
        }
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [formData, value]);

  const onChangeHandler = (e) => {
    let str = e.target.value;
    if (str === "") {
      setValue("");
    }
    if (str !== "") {
      setValue(str);
      onSubmitHandler(str);
    }
  };

  useEffect(() => {
    if (obj?.all_data?.f_value !== null) {
      setValue(obj?.all_data?.f_value);
    }
  }, []);

  return (
    <div
      id={`fieldBlock-textarea-content-${field?.FormValuesID}-displayed-${field?.FormValuesDisplayedID}`}
      style={{
        fontSize: `${field?.fontsize}pt`,
        color: colors.textBlue,
        // backgroundColor: colors.green,
        height: params.height,
        lineHeight: `${field?.fontsize}pt`,
      }}
      className="FieldBlock-fieldBlock-input-content"
    >
      <textarea
        style={{ fontSize: `${field?.fontsize}pt` }}
        maxLength={255}
        className={
          mappingid
            ? `fieldBlock-textarea-content fieldBlock-textarea-content-mappingid-${mappingid}`
            : "fieldBlock-textarea-content"
        }
        value={value}
        onChange={onChangeHandler}
      />
    </div>
  );
};

const toAddressFormatt = (v, v2, v3, v4, v5) => {
  let temp_v = "";
  let temp_v2 = "";
  let temp_v3 = "";
  let temp_v4 = "";
  let temp_v5 = "";
  if (v && v !== null) temp_v = v;
  if (v2 && v2 !== null) temp_v2 = v2;
  if (v3 && v3 !== null) temp_v3 = v3;
  if (v4 && v4 !== null) temp_v4 = v4;
  if (v5 && v5 !== null) temp_v5 = v5;
  let str = temp_v;
  if (v2) str = str + " " + temp_v2;
  if (v3) str = str + " " + temp_v3;
  if (v4) str = str + " " + temp_v4;
  if (v5) str = str + " " + temp_v5;
  return str;
};
