import React from "react";
import "./baseInput.css";

const BaseInput = ({
  height = "40px",
  width = "100%",
  className = "base",
  id,
  label,
  required,
  disabled = false,
  placeholder,
  type = "text",
  name,
  onChange,
  onBlur,
  value,
  no_borders,
  ref,
  defaultValue,
  ...props
}) => {
  return (
    <div
      className={`base-input-container base-input-container-${className}  ${
        required ? "base-input-block-required" : "base-input-block-default"
      } ${no_borders ? "base-input-container-line" : ""}`}
    >
      {label && (
        <div>
          <label htmlFor={id} className={"base-input-label"}>
            {label}
          </label>
        </div>
      )}
      <div
        className={`base-input-block ${
          required ? "base-input-block-required" : ""
        }`}
      >
        <input
          style={{ width: width, height: height }}
          // eslint-disable-next-line no-undef
          onChange={onChange}
          onBlur={onBlur}
          ref={ref}
          disabled={disabled}
          placeholder={placeholder}
          id={id}
          required={required}
          className={className}
          type={type}
          name={name}
          value={value}
          defaultValue={defaultValue}
          autoComplete="off"
          {...props}
        />
      </div>
    </div>
  );
};

export default BaseInput;
