import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { calculateTokenExpirationTime } from "../../utils/tokenExpiration";
import { useSessionStorage } from "../useSessionStorage/useSessionStorage";
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useSessionStorage("user", null);
  const [userToken, setUserToken] = useSessionStorage("access_token", null);
  const [userRefreshToken, setUserRefreshToken] = useSessionStorage(
    "refresh_token",
    null
  );
  const [userExpiresIn, setEserExpiresIn] = useSessionStorage(
    "expires_in",
    null
  );

  const navigate = useNavigate();

  // loginPage: "/auth/login",
  // mainPage: "/user/:user",

  // call this function when you want to authenticate the user
  const login = async (data) => {
    setUser(data);
    // navigate(`/user/${data}`);
  };

  const clearToken = async () => {
    setUserToken(null);
    setUserRefreshToken(null);
    setEserExpiresIn(null);
    setUser(null);
  };

  const setToken = async (data) => {
    setUserToken(data?.access_token);
    setUserRefreshToken(data?.refresh_token);
    if (data?.expires_in) {
      const expirationTime = calculateTokenExpirationTime(data?.expires_in);
      setEserExpiresIn(expirationTime.getTime() / 1000);
    }
  };

  // call this function to sign out logged in user
  const logout = () => {
    setUser(null);
    // navigate("/", { replace: true });
  };

  const value = useMemo(
    () => ({
      user,
      userToken,
      userRefreshToken,
      userExpiresIn,
      setToken,
      clearToken,
      login,
      logout,
    }),
    [user]
  );
  // console.log(value, "value");
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
