export const colors = {
  mainBg: "#141313",
  blue: "#2078BD",
  textBlue: "#001296",
  lightBlueBg: "#B3CCF1B8",
  bodyText: "#F2F2F2",
  red: "#FF2822",
  yellow: "#FFC805",
  yellowBg: "rgba(241, 241, 149, 0.6)",
  secondYellow: "#FFBF44",
  gray: "#908F8F",
  lightGray: "#f9f9f9",
  secondBg: "#2B2B2B",
  white: "#FFFFFF",
  green: "#28a745",
  lightGreen: "#b6eec3",
};
