import React, { useEffect, useRef, useState } from "react";
import { colors } from "../../constants/colors";
import { OptionsIcon } from "../../constants/icons";
import { useClickOutside } from "../../hooks";
import "./OptionsList.css";

function OptionsList() {
  const [isOpened, setIsOpened] = useState(false);

  const onClickHandler = () => {
    setIsOpened(!isOpened);
  };

  const clickOutsideHandler = () => {
    setIsOpened(false);
  };

  const modalRef = useRef(null);
  useClickOutside(modalRef, () => {
    clickOutsideHandler();
  });

  return (
    <div className="OptionsList-wrapper" ref={modalRef}>
      <div className="OptionsList-icon-wrapper" onClick={onClickHandler}>
        <OptionsIcon height={16} />
      </div>
      {isOpened && (
        <div className="OptionsList-list-wrapper">
          <div className="OptionsList-list-content">Send to Room</div>
          <div className="OptionsList-list-content">Send to eSign</div>
          <div className="OptionsList-list-content">Fill from Room</div>
        </div>
      )}
    </div>
  );
}

export default OptionsList;
