import React, { useEffect } from "react";
import { colors } from "../../constants/colors";
import { CloseIcon } from "../../constants/icons";
import { ClickOutside } from "../../ui";
import "./ConfirmMessage.css";

const ConfirmMessage = ({
  header,
  content,
  onConfirm,
  onCancel,
  headerColor = false,
  contentColor = false,
}) => {
  const closeAfter5s = () => {
    setTimeout(function () {
      onCancel();
    }, 10000);
  };
  useEffect(() => {
    closeAfter5s();
  }, []);

  return (
    <div className="confirm-message-wrapper">
      <div className="confirm-message-close" onClick={onCancel}>
        <CloseIcon width={24} height={24} color={colors.blue} />
      </div>
      <div className="confirm-message-content">
        <div className="confirm-message-content-header">
          <span style={{ color: headerColor ? headerColor : colors.mainBg }}>
            {header}
          </span>
        </div>
        <div className="confirm-message-content-text">
          <span style={{ color: contentColor ? contentColor : colors.mainBg }}>
            {content}
          </span>
        </div>
      </div>
      <div className="confirm-message-btns-wrapper">
        <div
          className="confirm-message-btn-confirm confirm-message-btn-block"
          onClick={onConfirm}
        >
          Confirm
        </div>
        <div
          className="confirm-message-btn-cansel confirm-message-btn-block"
          onClick={onCancel}
        >
          Cancel
        </div>
      </div>
    </div>
  );
};

export default ConfirmMessage;
