import React from "react";
import "./BaseButton.css";

const BaseButton = ({
  children,
  type = "submit",
  disabled,
  className,
  onClick,
}) => {
  return (
    <div
      className={`base-button-container base-button-container-${className}`}
      onClick={onClick}
    >
      <button type={type} disabled={disabled} className={`${className}`}>
        {children}
      </button>
    </div>
  );
};

export default BaseButton;
