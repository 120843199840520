import { combineReducers, configureStore } from "@reduxjs/toolkit";
import formsDataReducer from "./reducers/formsDataSlice";
import isFormOpenedReducer from "./reducers/isFormOpenedSlice";
import isDatePickerOpenedReducer from "./reducers/isDatePickerOpenedSlice";
import allWorkFormsDataReducer from "./reducers/allWorkFormsDataSlice";
import formToRemoveReducer from "./reducers/formToRemoveSlice";
import allFormsToRemoveReducer from "./reducers/allFormsToRemoveSlice";
import printFormsReducer from "./reducers/printFormsSlice";
import downloadFormsReducer from "./reducers/downloadFormsSlice";
import formsReducedReducer from "./reducers/formsReducedSlice";

const combinedReducer = combineReducers({
  isFormOpened: isFormOpenedReducer,
  isDatePickerOpened: isDatePickerOpenedReducer,
  formsData: formsDataReducer,
  allWorkFormsData: allWorkFormsDataReducer,
  formToRemove: formToRemoveReducer,
  allFormsToRemove: allFormsToRemoveReducer,
  printForms: printFormsReducer,
  downloadForms: downloadFormsReducer,
  formsReduced: formsReducedReducer,
});

const rootReducer = (state, action) => {
  return combinedReducer(state, action);
};

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
  });
};
