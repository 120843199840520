import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { fakeToken } from "./constants/fakeToken";
import { AppProvider } from "./context/AppContext";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./hooks";
import { IsAccessTokenProvider } from "./context/IsAccessTokenContext";

// {accessToken: undefined, loginUrl:undefined}

window.RealFormsInit = (options) => {
  if (options?.removeFormMessage) {
    options.removeFormMessage = "Do you really want to remove this form:";
  }
  if (options?.removeAllFormsMessage) {
    options.removeAllFormsMessage = "Do you really want to remove all forms?";
  }
  const root = ReactDOM.createRoot(document.getElementById("pdfReader"));
  root.render(
    <BrowserRouter>
      <AppProvider>
        <IsAccessTokenProvider>
          <AuthProvider>
            <App options={options} />
          </AuthProvider>
        </IsAccessTokenProvider>
      </AppProvider>
    </BrowserRouter>
  );
};

document.dispatchEvent(new Event("RealFormsReady"));

// window.RealFormsInit();
//use only it for dev
//window.RealFormsInit({ accessToken: fakeToken });
