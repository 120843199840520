import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isFeildFormOpened: null,
};

export const isFormOpenedSlice = createSlice({
  name: "isFormOpened",
  initialState,
  reducers: {
    setIsFormOpened(state, actions) {
      state.isFeildFormOpened = actions.payload;
    },
  },
});

export default isFormOpenedSlice.reducer;
