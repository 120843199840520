import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpenDownloadPopUp: null,
  formsToDownload: null,
  isConfirmToDownload: null,
};

export const downloadFormsSlice = createSlice({
  name: "downloadFormsSlice",
  initialState,
  reducers: {
    setIsOpenDownloadPopUp(state, actions) {
      state.isOpenDownloadPopUp = actions.payload;
    },
    setDownloadForms(state, actions) {
      state.formsToDownload = actions.payload;
    },
    setIsConfirmToDownloadPopUp(state, actions) {
      state.isConfirmToDownload = actions.payload;
    },
  },
});

export default downloadFormsSlice.reducer;
