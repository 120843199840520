import React from "react";
import "./ToggleSwitch.css";

const ToggleSwitch = ({ isToggled, setIsToggled }) => {
  const handleToggle = () => {
    // setIsToggled(!isToggled);
    setIsToggled(!isToggled);
  };

  return (
    <label className="toggle-switch">
      <input type="checkbox" checked={isToggled} onChange={handleToggle} />
      <span
        className="switch-slider"
        style={{ paddingLeft: isToggled ? "10px" : "26px" }}
      >
        {isToggled ? "Wide" : "Narrow"}
      </span>
    </label>
  );
};

export default ToggleSwitch;
