import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isFeildDatePickerOpened: null,
};

export const isDatePickerOpenedSlice = createSlice({
  name: "isDatePickerOpenedSlice",
  initialState,
  reducers: {
    setIsDatePickerOpened(state, actions) {
      state.isFeildDatePickerOpened = actions.payload;
    },
  },
});

export default isDatePickerOpenedSlice.reducer;
