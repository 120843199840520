import React, { useEffect, useRef, useState } from "react";
import { colors } from "../../constants/colors";
import {
  ArrowUpIcon,
  DownloadIcon,
  OptionsIcon,
  PrintIcon,
} from "../../constants/icons";
import { BaseInput, BaseInputList, Spiner, ToggleSwitch } from "../../ui";
import axios from "axios";
import "./MainHeaderBlock.css";
import "./responsive.css";
// import { fakeToken } from "../../constants/fakeToken";
import { useDispatch, useSelector } from "react-redux";
import { resetFormsData } from "../../store/actions/resetFormsData";
import { useAuth, useScreenSize } from "../../hooks";
import { setIsOpenPrintPopUp } from "../../store/actions/setIsOpenPrintPopUp";
import { setFormsToPrintPopUp } from "../../store/actions/setFormsToPrintPopUp";
import { setIsConfirmToPrintPopUp } from "../../store/actions/setIsConfirmToPrintPopUp";
import { setFormsToDownloadPopUp } from "../../store/actions/setFormsToDownloadPopUp";
import { setIsConfirmToDownloadPopUp } from "../../store/actions/setIsConfirmToDownloadPopUp";
import { setIsOpenDownloadPopUp } from "../../store/actions/setIsOpenDownloadPopUp";
import OptionsList from "../OptionsList/OptionsList";
import { setIsOpenAllFormsConfirmPopUp } from "../../store/actions/setIsOpenAllFormsConfirmPopUp";
import { useIsAccessToken } from "../../context/IsAccessTokenContext";
import {
  calculateTokenExpirationTime,
  isTokenExpired,
} from "../../utils/tokenExpiration";

let counter = 0;
let handleList = [];

function MainHeaderBlock({
  currentItem,
  isExpandedAll,
  setIsExpandedAll,
  isArrowsBlockClicked,
  setIsArrowsBlockClicked,
  multipleFormData,
  setMultipleFormData,
  allFormData,
  setAllFormData,
  initView,
  accessToken,
  isNarrowToggle,
  setIsNarrowToggle,
  userData,
  currentToken,
  setCurrentToken,
  selectedForms,
  setSelectedForms,
  currentList,
  setCurrentList,
  isFormsListVisible,
  setIsFormsListVisible,
}) {
  const { userExpiresIn, userRefreshToken, clearToken, setToken, userToken } =
    useAuth();
  const { tokenExpired, setTokenExpired } = useIsAccessToken();
  const screenSize = useScreenSize();
  const { formsToPrint, isConfirmToPrint } = useSelector(
    (state) => state.printForms
  );

  const { formsToDownload, isConfirmToDownload } = useSelector(
    (state) => state.downloadForms
  );

  const { data } = useSelector((state) => state.formsData);
  const [titleNum, setTitleNum] = useState("");
  const [titleName, setTitleName] = useState("");
  const [isAllFormsDataLoading, setIsAllFormsDataLoading] = useState(false);

  const [stillSendLoading, setStillSendLoading] = useState(false);

  const [allDataError, setAllDataError] = useState(null);

  const [allBlobPages, setAllBlobPages] = useState(null);
  const [blobStarted, setBlobStarted] = useState(false);

  const [focusedFormId, setFocusedFormId] = useState(0);
  const [currentFocusedForm, setCurrentFocusedForm] = useState(null);

  const [isRemoveAllForms, setIsRemoveAllForms] = useState(false);
  const [isAddFormList, setIsAddFormList] = useState(false);
  const [isAddLastForm, setIsAddLastForm] = useState(false);

  const [onFocusedForm, setOnFocusedForm] = useState(null);

  const onFormClickHandler = (form) => {
    setTimeout(
      () => setCurrentList([...currentList.filter((el) => el.id !== form.id)]),
      0
    );
    let temp = selectedForms.filter((f) => f.id === form.id);
    if (temp.length === 0) setSelectedForms([...selectedForms, form]);
  };

  useEffect(() => {
    if (currentFocusedForm && currentFocusedForm !== "") {
      onFormClickHandler(currentFocusedForm);
    }
  }, [currentFocusedForm]);

  useEffect(() => {
    if (onFocusedForm && onFocusedForm !== "") {
      let focusedClass = `liClass-${onFocusedForm?.id}`;
      scrollToBottom(focusedClass);
    }
  }, [onFocusedForm]);

  // scrollDetect(container);

  useEffect(() => {
    if (isAddLastForm) {
      if (currentList.length === 1) {
        onFormClickHandler(currentList[0]);
      }
      setIsAddLastForm(false);
    }
  }, [isAddLastForm]);

  useEffect(() => {
    if (selectedForms?.length > 0 && isRemoveAllForms) {
      removeAllFormsHandler();
      setIsRemoveAllForms(false);
    }
  }, [isRemoveAllForms, selectedForms?.length]);

  //rerender focused style when form in list was choosen
  useEffect(() => {
    if (
      counter > 2 &&
      selectedForms?.length > 0 &&
      stillSendLoading === false
    ) {
      const container = document.querySelectorAll(
        ".MainPage-header-left-search-wrapper"
      )[0];
      let tempHandleList = [];
      if (container) {
        tempHandleList.push(container.querySelectorAll("input")[0]);
        tempHandleList.push(
          container.querySelectorAll(".baseInput-Send-Btn-wrapper")[0]
        );
        tempHandleList.push(
          container.querySelectorAll(".baseInput-Remove-Btn-wrapper")[0]
        );

        const liDivs = container.querySelectorAll(
          ".MainPage-header-left-Search-input-li"
        );

        tempHandleList = [...handleList, ...liDivs];
        setTimeout(
          () =>
            tempHandleList[counter].classList.add(
              "handleListVisibleTabPress-FormsList"
            ),
          0
        );
      }
    }
  }, [selectedForms?.length, stillSendLoading]);

  const dispatch = useDispatch();

  function getBottomTitleString(FormID, userData) {
    let isUserData = false;
    let VanityNumber = "";
    let AgenteMail = "";
    let AgentChimeName = "";
    let AgentPhone = "";
    let noUserContent;
    let content;
    if (
      userData?.data?.agent?.data &&
      userData?.data?.agent?.data?.length > 0
    ) {
      isUserData = true;
    }

    if (isUserData && userData?.data?.agent) {
      AgentChimeName = userData?.data?.agent?.data[0]?.AgentChimeName
        ? `| ${userData?.data?.agent?.data[0]?.AgentChimeName}`
        : "";
      VanityNumber = userData?.data?.agent?.data[0]?.vanity_phone_standard
        ? `| ${userData?.data?.agent?.data[0]?.vanity_phone_standard}`
        : "";
      AgenteMail = userData?.data?.agent?.data[0]?.AgenteMail
        ? `| ${userData?.data?.agent?.data[0]?.AgenteMail}`
        : "";
      AgentPhone = userData?.data?.agent?.data[0]?.bizphone_standard
        ? `| ${userData?.data?.agent?.data[0]?.bizphone_standard}`
        : "";

      content = `Realty Texas ${AgentChimeName} ${VanityNumber} ${AgentPhone} ${AgenteMail} | ${FormID}`;
    }
    noUserContent = `Realty Texas | (800) 660-1022 | sales@realtytexas.com | ${FormID}`;
    if (isUserData && content) {
      return content;
    } else {
      return noUserContent;
    }
  }

  useEffect(() => {
    if (currentItem && multipleFormData.length > 0) {
      const apiResult = multipleFormData.filter(
        (item) => item.id === currentItem.id
      )[0];
      setTitleNum(`${apiResult.FormNum}`);
      setTitleName(`${apiResult.FormName}`);
    }
  }, [currentItem]);

  const isExpandedAllHandlerUp = () => {
    setIsExpandedAll(true);
  };

  const isExpandedAllHandlerDown = () => {
    setIsExpandedAll(false);
  };

  const isArrowsBlockClickedHandler = () => {
    setIsArrowsBlockClicked(!isArrowsBlockClicked);
  };

  const refreshAccessTokenGetAllFormsData = () => {
    const body = { refresh_token: userRefreshToken };
    axios
      .post(`https://api.realtytexas.net/v1/clients/web/refresh`, body)
      .then(async (res) => {
        await clearToken();

        if (res?.data?.access_token) {
          setToken({
            access_token: res?.data?.access_token,
            refresh_token: res?.data?.refresh_token,
            expires_in: res?.data?.expires_in,
          });
        }
        const token = res?.data?.access_token;
        const config = {
          headers: { Authorization: `Bearer ${token}` },
        };
        let params = "";
        // if (search) {
        //   params = `?search=${search}`;
        // }
        return axios
          .get(
            `https://api.realtytexas.net/v1/forms/?limit=1000${params}`,
            config
          )
          .then(function (response) {
            setAllFormData(response.data);
          })
          .catch(function (error) {
            console.log(error);
            setAllDataError(error);
          })
          .finally(function () {
            setIsAllFormsDataLoading(false);
          });
      })
      .catch(function (error) {
        clearToken();
        setTokenExpired(false);
        console.log(error?.response?.data?.message);
      });
  };

  const getAllFormsData = async (search) => {
    setIsAllFormsDataLoading(true);

    let isExpired = false;
    if (!currentToken) {
      isExpired = isTokenExpired(userExpiresIn);
    }
    if (isExpired) {
      refreshAccessTokenGetAllFormsData();
    } else {
      const token = currentToken ? currentToken : userToken;
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      let params = "";
      // if (search) {
      //   params = `?search=${search}`;
      // }
      axios
        // .get(`https://api.realtytexas.net/v1/forms${params}`, config)
        .get(
          `https://api.realtytexas.net/v1/forms/?limit=1000${params}`,
          config
        )
        .then(function (response) {
          setAllFormData(response.data);
          // dispatch(resetFormsData());
          // console.log(response.data, "response.data");
        })
        .catch(function (error) {
          console.log(error);
          setAllDataError(error);
        })
        .finally(function () {
          // always executed
          setIsAllFormsDataLoading(false);
        });
    }
  };

  useEffect(() => {
    if (allDataError) {
      document.dispatchEvent(new Event("RTFormsSystemTokenExpired"));
      // setCurrentToken("fakeToken");
      // window.RealFormsInit({ accessToken: "fakeToken" });
    }
  }, [allDataError]);

  const refreshAccessTokenGetSingleFormsData = (value) => {
    const body = { refresh_token: userRefreshToken };
    axios
      .post(`https://api.realtytexas.net/v1/clients/web/refresh`, body)
      .then(async (res) => {
        await clearToken();

        if (res?.data?.access_token) {
          setToken({
            access_token: res?.data?.access_token,
            refresh_token: res?.data?.refresh_token,
            expires_in: res?.data?.expires_in,
          });
        }
        const token = res?.data?.access_token;
        const config = {
          headers: { Authorization: `Bearer ${token}` },
        };
        return new Promise((resolve) => {
          axios
            .get(
              `https://api.realtytexas.net/v1/forms/${value}?include=formValue,formValue.formValuesDisplayed`,
              config
            )
            .then(function (response) {
              resolve(response.data.data);
            })
            .catch(function (error) {
              console.log(error);
            });
        });
      })
      .catch(function (error) {
        clearToken();
        setTokenExpired(false);
        console.log(error?.response?.data?.message);
      });
  };

  const getSingleFormsData = async (value) => {
    let isExpired = false;
    if (!currentToken) {
      isExpired = isTokenExpired(userExpiresIn);
    }
    if (isExpired) {
      refreshAccessTokenGetSingleFormsData(value);
    } else {
      const token = currentToken ? currentToken : userToken;
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      return new Promise((resolve) => {
        axios
          .get(
            `https://api.realtytexas.net/v1/forms/${value}?include=formValue,formValue.formValuesDisplayed`,
            config
          )
          .then(function (response) {
            resolve(response.data.data);
          })
          .catch(function (error) {
            console.log(error);
          });
      });
    }
  };

  const refreshAccessTokenDownloadFormsData = (value, FormFileName) => {
    const body = { refresh_token: userRefreshToken };
    axios
      .post(`https://api.realtytexas.net/v1/clients/web/refresh`, body)
      .then(async (res) => {
        await clearToken();

        if (res?.data?.access_token) {
          setToken({
            access_token: res?.data?.access_token,
            refresh_token: res?.data?.refresh_token,
            expires_in: res?.data?.expires_in,
          });
        }
        const token = res?.data?.access_token;
        const body = [...value];
        const config = {
          headers: { Authorization: `Bearer ${token}` },
          responseType: "blob",
        };
        return axios
          .post(
            `https://api.realtytexas.net/v1/forms/${value[0]?.FormID}/stamper`,
            body,
            config
          )
          .then((response) => {
            const pdfBlob = new Blob([response.data], {
              type: "application/pdf",
            });

            // Create a temporary URL for the Blob
            const url = window.URL.createObjectURL(pdfBlob);

            // Create a temporary <a> element to trigger the download
            const tempLink = document.createElement("a");
            tempLink.href = url;
            tempLink.setAttribute("download", `${FormFileName}`); // Set the desired filename for the downloaded file

            // Append the <a> element to the body and click it to trigger the download
            document.body.appendChild(tempLink);
            tempLink.click();

            // Clean up the temporary elements and URL
            document.body.removeChild(tempLink);
            window.URL.revokeObjectURL(url);
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch(function (error) {
        clearToken();
        setTokenExpired(false);
        console.log(error?.response?.data?.message);
      });
  };

  const downloadFormsData = async (check, value, FormFileName) => {
    if (check) {
      let isExpired = false;
      if (!currentToken) {
        isExpired = isTokenExpired(userExpiresIn);
      }
      if (isExpired) {
        refreshAccessTokenDownloadFormsData(value, FormFileName);
      } else {
        const token = currentToken ? currentToken : userToken;
        const body = [...value];
        const config = {
          headers: { Authorization: `Bearer ${token}` },
          responseType: "blob",
        };
        axios
          .post(
            `https://api.realtytexas.net/v1/forms/${value[0]?.FormID}/stamper`,
            body,
            config
          )
          .then((response) => {
            const pdfBlob = new Blob([response.data], {
              type: "application/pdf",
            });

            // Create a temporary URL for the Blob
            const url = window.URL.createObjectURL(pdfBlob);

            // Create a temporary <a> element to trigger the download
            const tempLink = document.createElement("a");
            tempLink.href = url;
            tempLink.setAttribute("download", `${FormFileName}`); // Set the desired filename for the downloaded file

            // Append the <a> element to the body and click it to trigger the download
            document.body.appendChild(tempLink);
            tempLink.click();

            // Clean up the temporary elements and URL
            document.body.removeChild(tempLink);
            window.URL.revokeObjectURL(url);
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    }
  };
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const printPdf = async (blob, index) => {
    return new Promise((resolve, reject) => {
      const blobUrl = URL.createObjectURL(blob);
      const newTab = window.open(blobUrl, "_blank");

      if (!newTab) {
        alert("Failed to open a new tab. Pop-up blocked?");
        console.log("Failed to open a new tab. Pop-up blocked?");
        reject("Failed to open a new tab. Pop-up blocked?");
        // resolve();
        return;
      }

      newTab.onload = () => {
        newTab.print();
        newTab.onafterprint = () => {
          newTab.close();
          URL.revokeObjectURL(blobUrl);
        };
        newTab.onclose = () => {
          URL.revokeObjectURL(blobUrl);
        };
      };
      resolve();
    });
  };

  const printMultiplePdfs = async (blobs) => {
    for (let i = 0; i < blobs.length; i++) {
      try {
        await printPdf(blobs[i], i);
        // await delay(1000); // Adjust the delay as needed
      } catch (error) {
        alert(error);
        break;
      }
    }
  };

  const refreshAccessTokenPrintFormsData = (value, setAllBlobPages) => {
    const body = { refresh_token: userRefreshToken };
    axios
      .post(`https://api.realtytexas.net/v1/clients/web/refresh`, body)
      .then(async (res) => {
        await clearToken();

        if (res?.data?.access_token) {
          setToken({
            access_token: res?.data?.access_token,
            refresh_token: res?.data?.refresh_token,
            expires_in: res?.data?.expires_in,
          });
        }
        const token = res?.data?.access_token;
        const body = [...value];
        const config = {
          headers: { Authorization: `Bearer ${token}` },
          responseType: "blob",
        };
        return axios
          .post(
            `https://api.realtytexas.net/v1/forms/${value[0]?.FormID}/stamper`,
            body,
            config
          )
          .then((response) => {
            setAllBlobPages((c) => {
              if (c?.length > 0) {
                return [...c, response.data];
              } else {
                return [response.data];
              }
            });
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch(function (error) {
        clearToken();
        setTokenExpired(false);
        console.log(error?.response?.data?.message);
      });
  };

  const printFormsData = async (check, value, setAllBlobPages) => {
    if (check) {
      let isExpired = false;
      if (!currentToken) {
        isExpired = isTokenExpired(userExpiresIn);
      }
      if (isExpired) {
        refreshAccessTokenPrintFormsData(value, setAllBlobPages);
      } else {
        const token = currentToken ? currentToken : userToken;
        const body = [...value];
        const config = {
          headers: { Authorization: `Bearer ${token}` },
          responseType: "blob",
        };
        axios
          .post(
            `https://api.realtytexas.net/v1/forms/${value[0]?.FormID}/stamper`,
            body,
            config
          )
          .then((response) => {
            setAllBlobPages((c) => {
              if (c?.length > 0) {
                return [...c, response.data];
              } else {
                return [response.data];
              }
            });
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    }
  };

  useEffect(() => {
    if (isConfirmToDownload) {
      downloadIconHandler();
      dispatch(setIsConfirmToDownloadPopUp(null));
    }
  }, [isConfirmToDownload]);

  const downloadIconHandler2 = () => {
    dispatch(setFormsToDownloadPopUp(null));
    if (multipleFormData?.length > 0) {
      dispatch(setIsOpenDownloadPopUp(true));
    }
  };

  const downloadIconHandler = () => {
    let tempArray = [];
    let tempArraySecond = [];

    if (data?.length > 0) {
      data?.map((obj, ind, objArr) => {
        if (
          objArr[ind + 1]?.FormID &&
          obj?.FormID === objArr[ind + 1]?.FormID
        ) {
          tempArray.push(obj);
        } else {
          tempArray.push(obj);
          tempArraySecond = [...tempArraySecond, [tempArray]];
          tempArray = [];
        }
      });
    }
    multipleFormData.map((obj_list, ind, objArr) => {
      let formValueID = obj_list?.formValue?.data[0]?.FormID;

      const FormFileName = obj_list?.FormFileName
        ? obj_list?.FormFileName
        : `${obj_list?.FormFileName}pdf`;

      const initObj = {
        FormID: formValueID,
        ContractDataID: null,
        FormValuesID: null,
        Value: null,
        Value_f2: null,
        Value_f3: null,
        Value_f4: null,
        Value_f5: null,
        FieldType: null,
        special_action: null,
      };

      const sourceSignatureArr = [];

      const sourceSignatureValue = getBottomTitleString(formValueID, userData);

      let sourceSignature = {
        FormID: formValueID,
        ContractDataID: null,
        FormValuesID: null,
        Value: sourceSignatureValue,
        Value_f2: null,
        Value_f3: null,
        Value_f4: null,
        Value_f5: null,
        FieldType: "text",
        special_action: "custom_field",
        x: 25,
        y: 1152,
        height: 13.33,
        width: null,
        fontsize: 8,
      };

      if (obj_list?.TotalPages) {
        for (let index = 0; index < obj_list?.TotalPages; index++) {
          sourceSignatureArr.push({
            ...sourceSignature,
            DisplayOnPage: index + 1,
          });
        }
      }

      let temp = tempArraySecond.filter(
        (item) => item[0][0]?.FormID === formValueID
      );
      let isValueConfirm = formsToDownload?.filter(
        (tt) => tt.FormID === formValueID
      );
      if (temp?.length > 0) {
        downloadFormsData(
          isValueConfirm?.length > 0,
          [...temp[0][0], ...sourceSignatureArr],
          FormFileName
        );
      } else
        downloadFormsData(
          isValueConfirm?.length > 0,
          [initObj, ...sourceSignatureArr],
          FormFileName
        );
    });
  };

  useEffect(() => {
    if (isConfirmToPrint) {
      printIconHandler();
      dispatch(setIsConfirmToPrintPopUp(null));
    }
  }, [isConfirmToPrint]);

  const printIconHandler2 = () => {
    dispatch(setFormsToPrintPopUp(null));
    if (multipleFormData?.length > 0) {
      dispatch(setIsOpenPrintPopUp(true));
    }
  };

  const printIconHandler = () => {
    setBlobStarted(!blobStarted);
    let tempArray = [];
    let tempArraySecond = [];

    if (data?.length > 0) {
      data?.map((obj, ind, objArr) => {
        if (
          objArr[ind + 1]?.FormID &&
          obj?.FormID === objArr[ind + 1]?.FormID
        ) {
          tempArray.push(obj);
        } else {
          tempArray.push(obj);
          tempArraySecond = [...tempArraySecond, [tempArray]];
          tempArray = [];
        }
      });
    }
    multipleFormData.map((obj_list) => {
      let formValueID = obj_list?.formValue?.data[0]?.FormID;

      const initObj = {
        FormID: formValueID,
        ContractDataID: null,
        FormValuesID: null,
        Value: null,
        Value_f2: null,
        Value_f3: null,
        Value_f4: null,
        Value_f5: null,
        FieldType: null,
        special_action: null,
      };
      let temp = tempArraySecond.filter(
        (item) => item[0][0]?.FormID === formValueID
      );
      let isValueConfirm = formsToPrint?.filter(
        (tt) => tt.FormID === formValueID
      );

      const sourceSignatureArr = [];

      const sourceSignatureValue = getBottomTitleString(formValueID, userData);

      let sourceSignature = {
        FormID: formValueID,
        ContractDataID: null,
        FormValuesID: null,
        Value: sourceSignatureValue,
        Value_f2: null,
        Value_f3: null,
        Value_f4: null,
        Value_f5: null,
        FieldType: "text",
        special_action: "custom_field",
        x: 25,
        y: 1152,
        height: 13.33,
        width: null,
        fontsize: 8,
      };

      if (obj_list?.TotalPages) {
        for (let index = 0; index < obj_list?.TotalPages; index++) {
          sourceSignatureArr.push({
            ...sourceSignature,
            DisplayOnPage: index + 1,
          });
        }
      }

      if (temp?.length > 0) {
        printFormsData(
          isValueConfirm?.length > 0,
          [...temp[0][0], ...sourceSignatureArr],
          setAllBlobPages
        );
      } else {
        printFormsData(
          isValueConfirm?.length > 0,
          [initObj, ...sourceSignatureArr],
          setAllBlobPages
        );
      }
    });
  };

  useEffect(() => {
    if (
      formsToPrint?.length > 0 &&
      formsToPrint?.length === allBlobPages?.length //whait for seting all the blobs
    ) {
      printMultiplePdfs([...allBlobPages]);
      setAllBlobPages([]);
    }
  }, [allBlobPages]);

  //TAB FUNCTIONALITY IF isFormsListVisible

  const selectedFormsRef = useRef(selectedForms);

  useEffect(() => {
    selectedFormsRef.current = selectedForms;
  }, [selectedForms]);

  useEffect(() => {
    if (isFormsListVisible && tokenExpired) {
      const handleListVisibleTabPressWithParams = (event) => {
        handleListVisibleTabPress(
          event,
          setFocusedFormId,
          focusedFormId,
          allFormData?.data,
          setCurrentFocusedForm,
          selectedFormsRef,
          setIsFormsListVisible
        );
      };

      resetHandleList();
      document.addEventListener("keydown", handleListVisibleTabPressWithParams);
      document
        .getElementById("MainPage-header-wrapper")
        .querySelectorAll("input")[0]
        .addEventListener("focus", () => handleListInputFocus());
      return () => {
        document.removeEventListener(
          "keydown",
          handleListVisibleTabPressWithParams
        );
        document
          ?.getElementById("MainPage-header-wrapper")
          ?.querySelectorAll("input")[0]
          ?.removeEventListener("focus", () => handleListInputFocus());
      };
    } else {
      let div = document
        .getElementById("MainPage-header-wrapper")
        .querySelectorAll(".handleListVisibleTabPress-FormsList")[0];
      if (div) {
        div.classList.remove("handleListVisibleTabPress-FormsList");
      }
    }
  }, [isFormsListVisible, tokenExpired]);

  const handleListInputFocus = () => {
    counter = 0;
    let divs = document.querySelectorAll(
      ".handleListVisibleTabPress-FormsList"
    );

    if (divs) {
      divs[0]?.classList.remove("handleListVisibleTabPress-FormsList");
    }
  };

  const resetHandleList = () => {
    handleList = [];
    counter = 0;
  };

  const removeAllFormsHandler = () => {
    dispatch(setIsOpenAllFormsConfirmPopUp(true));
    setIsFormsListVisible(false);
  };

  const focusedListEnterHandler = (list, container, setCurrentFocusedForm) => {
    handleList = [];
    if (container) {
      handleList.push(container.querySelectorAll("input")[0]);
      handleList.push(
        container.querySelectorAll(".baseInput-Send-Btn-wrapper")[0]
      );
      handleList.push(
        container.querySelectorAll(".baseInput-Remove-Btn-wrapper")[0]
      );

      const liDivs = container.querySelectorAll(
        ".MainPage-header-left-Search-input-li"
      );

      handleList = [...handleList, ...liDivs];
      const formId = handleList[counter]?.classList[0].replace(/[^0-9]/g, "");
      const focusedForm = list?.filter((form) => +form.id === +formId);
      setCurrentFocusedForm(focusedForm[0]);
    }
  };

  //add the class to the headers blocks

  function focusDivTab(index, divs) {
    divs?.map((el) =>
      el.classList.remove("handleListVisibleTabPress-FormsList")
    );
    if (divs && divs?.length > 0) {
      divs[index]?.classList.add("handleListVisibleTabPress-FormsList");
      if (index !== 0) {
        divs[0].blur();
      } else {
        divs[index]?.focus();
      }
    }
  }

  function focusDivArrowUp(index, divs) {
    if (divs && divs?.length > 0) {
      divs?.map((el) =>
        el.classList.remove("handleListVisibleTabPress-FormsList")
      );
      if (index === 1) {
        divs[2]?.classList.remove("handleListVisibleTabPress-FormsList");
        divs[1]?.classList.add("handleListVisibleTabPress-FormsList");
      }
      if (index === 0) {
        divs[1]?.classList.remove("handleListVisibleTabPress-FormsList");
        divs[0]?.classList.add("handleListVisibleTabPress-FormsList");
      }
      if (index !== 0 && index !== 1) {
        if (index !== divs.length - 1) {
          divs[index + 1]?.classList.remove(
            "handleListVisibleTabPress-FormsList"
          );
        } else {
          divs[0]?.classList.remove("handleListVisibleTabPress-FormsList");
        }
        divs[index]?.classList.add("handleListVisibleTabPress-FormsList");
      }

      if (index !== 0) {
        divs[0]?.blur();
      } else {
        divs[index]?.focus();
      }
    }
  }

  function scrollDetect(container) {
    var lastScroll = 0;

    container.onscroll = function () {
      let currentScroll =
        document.documentElement.scrollTop || document.body.scrollTop; // Get Current Scroll Value

      if (currentScroll > 0 && lastScroll <= currentScroll) {
        lastScroll = currentScroll;
        document.getElementById("scrollLoc").innerHTML = "Scrolling DOWN";
      } else {
        lastScroll = currentScroll;
        document.getElementById("scrollLoc").innerHTML = "Scrolling UP";
      }
    };
  }

  const scrollToBottom = (className) => {
    const offset = 4;
    const container = document.querySelectorAll(
      ".MainPage-header-left-search-wrapper"
    )[0];
    var wrapper = container.querySelectorAll(`.baseInput-ul-wrapper`)[0];
    var targetElement = container.querySelectorAll(`.${className}`)[0];

    // Ensure that the container has scrollable content
    if (wrapper && targetElement) {
      targetElement.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
    // setTimeout(() => {
    //   wrapper.scrollTop -= offset;
    // }, 500);
  };

  function focusDivShiftTab(index, divs) {
    if (divs && divs?.length > 0) {
      if (index === 1) {
        divs[2]?.classList.remove("handleListVisibleTabPress-FormsList");
        divs[1]?.classList.add("handleListVisibleTabPress-FormsList");
      }
      if (index === 0) {
        divs[1]?.classList.remove("handleListVisibleTabPress-FormsList");
        divs[0]?.classList.add("handleListVisibleTabPress-FormsList");
      }
      if (index !== 0 && index !== 1) {
        if (index !== divs.length - 1) {
          divs[index + 1]?.classList.remove(
            "handleListVisibleTabPress-FormsList"
          );
        } else {
          divs[0]?.classList.remove("handleListVisibleTabPress-FormsList");
        }
        divs[index]?.classList.add("handleListVisibleTabPress-FormsList");
      }

      if (index !== 0) {
        divs[0]?.blur();
      } else {
        divs[index]?.focus();
      }
    }
  }

  const handleListVisibleTabPress = (
    event,
    setFocusedFormId,
    focusedFormId,
    list,
    setCurrentFocusedForm,
    selectedFormsRef,
    setIsFormsListVisible
  ) => {
    handleList = [];
    console.log(selectedFormsRef.current, "selectedFormsselectedForms");
    const container = document.querySelectorAll(
      ".MainPage-header-left-search-wrapper"
    )[0];
    if (event.key === "Escape") {
      event.preventDefault();
      setIsFormsListVisible(false);
    }
    if (event.keyCode === 38 || event.keyCode === 40) {
      event.preventDefault();

      if (container) {
        handleList.push(container.querySelectorAll("input")[0]);
        handleList.push(
          container.querySelectorAll(".baseInput-Send-Btn-wrapper")[0]
        );
        handleList.push(
          container.querySelectorAll(".baseInput-Remove-Btn-wrapper")[0]
        );

        const liDivs = container.querySelectorAll(
          ".MainPage-header-left-Search-input-li"
        );

        handleList = [...handleList, ...liDivs];
      }
      if (event.keyCode === 40 && counter >= 3) {
        if (counter === handleList.length - 1) {
          counter = handleList.length - 1;
        } else {
          counter++;
        }
      }
      if (event.keyCode === 38 && counter >= 3) {
        if (counter === 3) {
          counter = 3;
        } else {
          counter--;
        }
      }

      if (container && handleList.length > 0) {
        if (counter > 2) {
          setFocusedFormId(
            handleList[counter].classList[0].replace(/[^0-9]/g, "")
          );
          focusedListEnterHandler(list, container, setOnFocusedForm);
        }
        if (event.keyCode === 40 && counter > 2) {
          focusDivTab(counter, handleList);
        }
        if (event.keyCode === 38 && counter > 2) {
          focusDivArrowUp(counter, handleList);
        }
      }
    }
    if (
      event.key === "Tab" ||
      (event.shiftKey &&
        event.keyCode === 9 &&
        !(event.keyCode === 38 || event.keyCode === 40))
    ) {
      event.preventDefault();

      if (container) {
        handleList.push(container.querySelectorAll("input")[0]);
        handleList.push(
          container.querySelectorAll(".baseInput-Send-Btn-wrapper")[0]
        );
        handleList.push(
          container.querySelectorAll(".baseInput-Remove-Btn-wrapper")[0]
        );

        const liDivs = container.querySelectorAll(
          ".MainPage-header-left-Search-input-li"
        );

        handleList = [...handleList, ...liDivs];
      }
      if (event.key === "Tab" && !(event.shiftKey && event.keyCode === 9)) {
        if (counter > 2) {
          counter = 0;
        } else {
          counter++;
        }
      }
      if (event.shiftKey && event.keyCode === 9) {
        if (counter === 0) {
          counter = 3;
        } else if (counter >= 3) {
          counter = 2;
        } else {
          counter--;
        }
      }

      if (container && handleList.length > 0) {
        if (counter > 2) {
          setFocusedFormId(
            handleList[counter].classList[0].replace(/[^0-9]/g, "")
          );
        }
        if (event.key === "Tab") {
          focusDivTab(counter, handleList);
        }
        if (event.shiftKey && event.keyCode === 9) {
          focusDivShiftTab(counter, handleList);
        }
      }
    }
    if (event.key === "Enter") {
      event.preventDefault();
      if (handleList) {
        if (counter > 2) {
          focusedListEnterHandler(list, container, setCurrentFocusedForm);
        }
        if (counter === 2) {
          setIsRemoveAllForms(true);
        }

        if (counter === 1) {
          setIsAddLastForm(true);
        }
      }
    }
  };

  // const onFormHandler = () => {
  //   setTokenExpired(false);
  // };

  return (
    <div
      className={
        !initView
          ? "MainPage-header-wrapper"
          : "MainPage-header-wrapper-initial"
      }
      id="MainPage-header-wrapper"
    >
      <div
        // className={
        //   initView
        //     ? "MainPage-header-left MainPage-header-left-init"
        //     : "MainPage-header-left MainPage-header-left-NotInit"
        // }
        className="MainPage-header-left MainPage-header-left-NotInit"
      >
        <div className="MainPage-header-left-search-wrapper">
          <BaseInputList
            height="36px"
            no_borders
            placeholder={
              !initView && screenSize[0] <= 768 ? "Search" : "Search Form"
            }
            // onChange={(e) => setSearchValue(e.target.value)}
            // value={searchValue}
            list={allFormData?.data}
            getList={getAllFormsData}
            defaultList={allFormData?.data}
            isLoading={isAllFormsDataLoading}
            getSingleFormsData={getSingleFormsData}
            setMultipleFormData={setMultipleFormData}
            multipleFormData={multipleFormData}
            initView={initView}
            setStillSendLoading={setStillSendLoading}
            disabled={stillSendLoading || isAllFormsDataLoading}
            stillSendLoading={stillSendLoading}
            isAllFormsDataLoading={isAllFormsDataLoading}
            errorData={allDataError}
            setDataError={setAllDataError}
            id="MainPage-header-left-Search-input"
            selectedForms={selectedForms}
            setSelectedForms={setSelectedForms}
            currentList={currentList}
            setCurrentList={setCurrentList}
            isFormsListVisible={isFormsListVisible}
            setIsFormsListVisible={setIsFormsListVisible}
            liClass="MainPage-header-left-Search-input-li"
          />
        </div>
        {!initView && (
          <div
            className="MainPage-header-arrows-wrapper"
            onClick={isArrowsBlockClickedHandler}
          >
            <div
              className="MainPage-header-arrow-block-up"
              onClick={isExpandedAllHandlerUp}
            >
              <ArrowUpIcon
                color={!isExpandedAll ? colors.mainBg : colors.blue}
                width={24}
                height={14}
              />
            </div>
            <div
              className="MainPage-header-arrow-block-down"
              onClick={isExpandedAllHandlerDown}
            >
              <ArrowUpIcon
                color={isExpandedAll ? colors.mainBg : colors.blue}
                width={24}
                height={14}
              />
            </div>
          </div>
        )}
      </div>

      {((screenSize[0] <= 768 && !initView) || screenSize[0] > 768) && (
        <div
          // className={
          //   initView
          //     ? "MainPage-header-right MainPage-header-right-init"
          //     : "MainPage-header-right MainPage-header-right-NotInit"
          // }
          className="MainPage-header-right MainPage-header-right-NotInit"
          onClick={() => {
            console.log(data, "formsData");
          }}
        >
          {screenSize[0] > 1024 && !initView && (
            <div className="MainPage-header-toggleSwitch-wrapper">
              <ToggleSwitch
                isToggled={isNarrowToggle}
                setIsToggled={setIsNarrowToggle}
              />
            </div>
          )}
          <div
            className={
              multipleFormData && multipleFormData?.length > 0
                ? "MainPage-header-icon-wrapper"
                : "MainPage-header-icon-wrapper-disabled"
            }
            // onClick={downloadIconHandler}
            onClick={downloadIconHandler2}
          >
            <DownloadIcon
              color={
                multipleFormData && multipleFormData?.length > 0
                  ? colors.mainBg
                  : colors.gray
              }
            />
          </div>
          <div
            className={
              multipleFormData?.length > 0
                ? "MainPage-header-icon-wrapper"
                : "MainPage-header-icon-wrapper-disabled"
            }
            // onClick={printIconHandler}
            onClick={printIconHandler2}
          >
            <PrintIcon
              color={multipleFormData?.length > 0 ? colors.mainBg : colors.gray}
            />
          </div>
          <div className="MainPage-header-icon-wrapper">
            <OptionsList />
          </div>
        </div>
      )}
    </div>
  );
}

export default MainHeaderBlock;
