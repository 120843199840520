import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: null,
};

export const formsDataSlice = createSlice({
  name: "formsData",
  initialState,
  reducers: {
    addFormsData(state, actions) {
      if (state.data !== null) {
        state.data = [
          ...state.data.filter(
            (f) => f.FormValuesID !== actions.payload.FormValuesID
          ),
          actions.payload,
        ];
      } else {
        state.data = [actions.payload];
      }
    },
    removeFormsData(state, actions) {
      if (state.data !== null) {
        state.data = [
          ...state.data.filter(
            (f) => f.FormValuesID !== actions.payload.FormValuesID
          ),
        ];
      } 
    },
    resetFormsData(state) {
      state.data = null;
    },
  },
});

export default formsDataSlice.reducer;
