import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DownloadPopUp,
  LeftMainBlock,
  PrintPopUp,
  RightMainBlock,
} from "../../components";
import { setRemoveAllForms } from "../../store/actions/setAllFormsToRemove";
import { setFormsToPrintPopUp } from "../../store/actions/setFormsToPrintPopUp";
import { setFormToRemove } from "../../store/actions/setFormToRemove";
import { setIsConfirmToPrintPopUp } from "../../store/actions/setIsConfirmToPrintPopUp";
import { setIsOpenDownloadPopUp } from "../../store/actions/setIsOpenDownloadPopUp";
import { setFormsToDownloadPopUp } from "../../store/actions/setFormsToDownloadPopUp";

import { setIsOpenAllFormsConfirmPopUp } from "../../store/actions/setIsOpenAllFormsConfirmPopUp";
import { setIsOpenPrintPopUp } from "../../store/actions/setIsOpenPrintPopUp";
import { AlertMessage, ConfirmMessage } from "../../ui";
import "./MainBlock.css";
import { setIsConfirmToDownloadPopUp } from "../../store/actions/setIsConfirmToDownloadPopUp";
import { useScreenSize } from "../../hooks";

function MainBlock({
  currentItem,
  setCurrentItem,
  isExpandedAll,
  setIsExpandedAll,
  isArrowsBlockClicked,
  setIsArrowsBlockClicked,
  multipleFormData,
  setMultipleFormData,
  initView,
  options,
  isNarrowToggle,
  setIsNarrowToggle,
  userData,
  selectedForms,
  setSelectedForms,
  isFormsListVisible,
  setIsFormsListVisible,
}) {
  const dispatch = useDispatch();

  const { form } = useSelector((state) => state.formToRemove);
  const { removeAllForms, isOpenConfirmPopUp } = useSelector(
    (state) => state.allFormsToRemove
  );

  const [isReducedTitle, setIsReducedTitle] = useState(false);

  const [isDragReorder, setIsDragReorder] = useState(null);

  const { isOpenPrintPopUp } = useSelector((state) => state.printForms);
  const { isOpenDownloadPopUp } = useSelector((state) => state.downloadForms);

  const [currentPage, setCurrentPage] = useState(null);
  const [prevPdf, setPrevPdf] = useState(null);
  const [leftBarOpened, setLeftBarOpened] = useState(false);
  const [removeForm, setRemoveForm] = useState(null);

  const [formsResult, setFormsResult] = useState(null);

  const screenSize = useScreenSize();

  useEffect(() => {
    setLeftBarOpened(false);
    setIsNarrowToggle(true);
  }, [screenSize]);

  useEffect(() => {
    if (removeForm && form?.id && multipleFormData?.length > 1) {
      setMultipleFormData([
        ...multipleFormData.filter((el) => el.id !== form?.id),
      ]);
      setSelectedForms(selectedForms.filter((el) => el.id !== form?.id));
      dispatch(setFormToRemove(null));
      setRemoveForm(null);
    }
  }, [removeForm]);

  const onCancelRemoveFormHandler = () => {
    dispatch(setFormToRemove(null));
  };

  const onCancelRemoveAllFormsHandler = () => {
    dispatch(setIsOpenAllFormsConfirmPopUp(null));
  };

  const onConfirmRemoveAllFormsHandler = () => {
    dispatch(setRemoveAllForms(true));
  };

  const onConfirmRemoveFormHandler = () => {
    setRemoveForm(true);
  };

  const onCancelPrintPopUpHandler = () => {
    dispatch(setIsOpenPrintPopUp(false));
    dispatch(setFormsToPrintPopUp(null));
  };

  const onConfirmPrintPopUpHandler = () => {
    dispatch(setIsConfirmToPrintPopUp(true));
    dispatch(setIsOpenPrintPopUp(false));
    // dispatch(setFormsToPrintPopUp(null));
  };

  const onConfirmDownloadPopUpHandler = () => {
    dispatch(setIsConfirmToDownloadPopUp(true));
    dispatch(setIsOpenDownloadPopUp(false));
    // dispatch(setFormsToPrintPopUp(null));
  };

  const onCancelDownloadPopUpHandler = () => {
    dispatch(setIsOpenDownloadPopUp(false));
    dispatch(setFormsToDownloadPopUp(null));
  };

  let removeFormMessage = "";
  useEffect(() => {
    if (form?.id && options?.removeFormMessage) {
      removeFormMessage =
        options?.removeFormMessage + " " + form?.FormNum + "?";
    }
  }, [form]);

  return (
    <div className="MainBlock-wrapper">
      <div
        className={
          leftBarOpened
            ? "MainBlock-wrapper-left MainBlock-wrapper-left-opened"
            : !isNarrowToggle
            ? "MainBlock-wrapper-left MainBlock-wrapper-left-closed MainBlock-wrapper-left-isNarrow"
            : isReducedTitle
            ? "MainBlock-wrapper-left MainBlock-wrapper-left-closed MainBlock-wrapper-left-isNotNarrow MainBlock-wrapper-left-isReducedTitle"
            : "MainBlock-wrapper-left MainBlock-wrapper-left-closed MainBlock-wrapper-left-isNotNarrow"
        }
      >
        {form?.id && multipleFormData?.length > 1 && (
          <ConfirmMessage
            // header="Error!"
            content={
              options?.removeFormMessage
                ? removeFormMessage
                : `Do you realy want to remove ${form?.FormNum} form?`
            }
            onConfirm={onConfirmRemoveFormHandler}
            onCancel={onCancelRemoveFormHandler}
          />
        )}

        {form?.id && multipleFormData?.length === 1 && (
          <ConfirmMessage
            content={
              options?.removeAllFormsMessage
                ? options.removeAllFormsMessage
                : `Do you realy want to remove ${form?.FormNum} form?`
            }
            onConfirm={() => {
              onConfirmRemoveAllFormsHandler();
              dispatch(setFormToRemove(null));
              setRemoveForm(null);
            }}
            onCancel={onCancelRemoveAllFormsHandler}
          />
        )}
        {isOpenConfirmPopUp && (
          <ConfirmMessage
            content={
              options?.removeAllFormsMessage
                ? options.removeAllFormsMessage
                : `Do you realy want to remove all forms?`
            }
            onConfirm={onConfirmRemoveAllFormsHandler}
            onCancel={onCancelRemoveAllFormsHandler}
          />
        )}
        {isOpenPrintPopUp && (
          <PrintPopUp
            onConfirm={onConfirmPrintPopUpHandler}
            onCancel={onCancelPrintPopUpHandler}
            data={multipleFormData}
          />
        )}
        {isOpenDownloadPopUp && (
          <DownloadPopUp
            onConfirm={onConfirmDownloadPopUpHandler}
            onCancel={onCancelDownloadPopUpHandler}
            data={multipleFormData}
          />
        )}

        <LeftMainBlock
          currentItem={currentItem}
          setCurrentItem={setCurrentItem}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setPrevPdf={setPrevPdf}
          isExpandedAll={isExpandedAll}
          isArrowsBlockClicked={isArrowsBlockClicked}
          setIsArrowsBlockClicked={setIsArrowsBlockClicked}
          leftBarOpened={leftBarOpened}
          setLeftBarOpened={setLeftBarOpened}
          initView={initView}
          multipleFormData={multipleFormData}
          setMultipleFormData={setMultipleFormData}
          isNarrowToggle={isNarrowToggle}
          formsResult={formsResult}
          setFormsResult={setFormsResult}
          isDragReorder={isDragReorder}
          setIsDragReorder={setIsDragReorder}
          isReducedTitle={isReducedTitle}
          setIsReducedTitle={setIsReducedTitle}
        />
      </div>

      <div
        className={
          isNarrowToggle
            ? isReducedTitle
              ? "MainBlock-wrapper-right MainBlock-wrapper-right-isReducedTitle"
              : "MainBlock-wrapper-right"
            : "MainBlock-wrapper-right-narrow"
        }
      >
        <RightMainBlock
          docs={multipleFormData}
          currentPage={currentPage}
          currentItem={currentItem}
          prevPdf={prevPdf}
          setPrevPdf={setPrevPdf}
          setCurrentItem={setCurrentItem}
          multipleFormData={multipleFormData}
          leftBarOpened={leftBarOpened}
          setCurrentPage={setCurrentPage}
          removeForm={removeForm}
          isNarrowToggle={isNarrowToggle}
          formsResult={formsResult}
          userData={userData}
          isDragReorder={isDragReorder}
          setIsDragReorder={setIsDragReorder}
          isFormsListVisible={isFormsListVisible}
          setIsFormsListVisible={setIsFormsListVisible}
          isReducedTitle={isReducedTitle}
          setIsReducedTitle={setIsReducedTitle}
        />

        {/* {!currentItem && <div className="MainBlock-wrapper-empty-page"></div>} */}
        <div className="MainBlock-wrapper-right-content"></div>
      </div>
    </div>
  );
}

export default MainBlock;
