import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { colors } from "../../constants/colors";
import { CloseIcon } from "../../constants/icons";
import { setIsFormOpened } from "../../store/actions/setIsFormOpened";
import { BaseButton, BaseInput, ClickOutside } from "../../ui";
import * as Yup from "yup";
import "./FieldForm.css";
import "./responsive.css";
import { addFormsData } from "../../store/actions/addFormsData";
import { addAllWorkFormsData } from "../../store/actions/addAllWorkFormsData";
import DatePicker from "../DatePicker/DatePicker";
import { setIsDatePickerOpened } from "../../store/actions/setIsDatePickerOpened";
import { toIsoFormat } from "../../utils/dateToIsoFormat";

function FieldForm({ data, savedData, allSavedData }) {
  const { isFeildDatePickerOpened } = useSelector(
    (state) => state.isDatePickerOpened
  );
  const [currendSavedData, setCurrendSavedData] = useState(null);
  const [isDatePiker, setIsDatePiker] = useState(false);
  const [fields, setFields] = useState(null);
  const [fieldsInitValues, setFieldsInitValues] = useState(null);
  const [fieldsSchemaObj, setFieldsSchemaObj] = useState(null);
  const [formData, setFormData] = useState(null);
  const [allFormData, setAllFormData] = useState(null);

  const datePickerRef = useRef(null);
  const flatpickrInstance = useRef(null);

  const [datePickerYYYY, setDatePickerYYYY] = useState("");
  const [datePickerMMMM, setDatePickerMMMM] = useState("");
  const [datePickerMM, setDatePickerMM] = useState("");
  const [datePickerDD, setDatePickerDD] = useState("");
  const [datePickerYY, setDatePickerYY] = useState("");

  const handleDateForm = (selectedDates) => {
    setDatePickerYYYY(toIsoFormat(new Date(selectedDates[0])));
    setDatePickerMM(toIsoFormat(new Date(selectedDates[0]), "month"));
    setDatePickerDD(toIsoFormat(new Date(selectedDates[0]), "day"));
    setDatePickerYY(toIsoFormat(new Date(selectedDates[0]), "year-YY"));
    setDatePickerMMMM(toIsoFormat(new Date(selectedDates[0]), "month-MMMM"));
  };

  const dispatch = useDispatch();

  const onCloseHandler = () => {
    dispatch(setIsFormOpened(false));
    dispatch(setIsDatePickerOpened(false));
  };

  const onCancelHandler = () => {
    dispatch(setIsFormOpened(false));
    dispatch(setIsDatePickerOpened(false));
  };

  const onSubmitHandler = (values) => {
    setFormData({
      FormID: data.all_data.FormID,
      ContractDataID: null,
      FormValuesID: data.all_data.FormValuesID,
      Value: values?.f_name ? values?.f_name : null,
      Value_f2: values?.f2_name ? values?.f2_name : null,
      Value_f3: values?.f3_name ? values?.f3_name : null,
      Value_f4: values?.f4_name ? values?.f4_name : null,
      Value_f5: values?.f5_name ? values?.f5_name : null,
      FieldType: data.all_data.f_fieldtype,
      special_action: values.special_action,
    });
    setAllFormData({
      FormID: data.all_data.FormID,
      ContractDataID: null,
      FormValuesID: data.all_data.FormValuesID,
      Value: values?.f_name ? values?.f_name : null,
      Value_f2: values?.f2_name ? values?.f2_name : null,
      Value_f3: values?.f3_name ? values?.f3_name : null,
      Value_f4: values?.f4_name ? values?.f4_name : null,
      Value_f5: values?.f5_name ? values?.f5_name : null,
      FieldType: data.all_data.f_fieldtype,
      fieldData: data.field,
      special_action: values.special_action,
      mappingid: data?.all_data?.mappingid,
    });
  };

  // console.log(data, "FieldForm");

  useEffect(() => {
    let savedArr = null;
    let savedArrObj = {
      Value: null,
      Value_f2: null,
      Value_f3: null,
      Value_f4: null,
      Value_f5: null,
    };
    if (savedData && allSavedData && data.all_data.f_fieldtype === "datetime") {
      savedArr = savedData?.filter(
        (t) => t.FormValuesID === data.all_data?.FormValuesID
      );
      allSavedData?.map((t) => {
        if (t?.mappingid === data.all_data?.mappingid) {
          savedArrObj.Value = t?.Value ? t?.Value : savedArr[0].Value;
          savedArrObj.Value_f2 = t?.Value_f2
            ? t?.Value_f2
            : savedArr[0].Value_f2;
          savedArrObj.Value_f3 = t?.Value_f3
            ? t?.Value_f3
            : savedArr[0].Value_f3;
          savedArrObj.Value_f4 = t?.Value_f4
            ? t?.Value_f4
            : savedArr[0].Value_f4;
          savedArrObj.Value_f5 = t?.Value_f5
            ? t?.Value_f5
            : savedArr[0].Value_f5;
        }
      });
    }
    if (data.all_data.f_fieldtype === "datetime") {
      setDatePickerYYYY(savedArrObj.Value);
      setDatePickerMM(savedArrObj.Value_f2);
      setDatePickerDD(savedArrObj.Value_f3);
      setDatePickerYY(savedArrObj.Value_f4);
      setDatePickerMMMM(savedArrObj.Value_f5);
    }
  }, []);

  const getFormFields = (f) => {
    let savedArr = null;
    let savedArrObj = {
      Value: null,
      Value_f2: null,
      Value_f3: null,
      Value_f4: null,
      Value_f5: null,
    };

    if (savedData && allSavedData) {
      savedArr = savedData?.filter((t) => t.FormValuesID === f.FormValuesID);
      // console.log(savedArr, "savedArrsavedArrsavedArr");
      allSavedData?.map((t) => {
        if (t?.mappingid === f?.mappingid) {
          if (t?.Value) savedArrObj.Value = t?.Value;
          if (t?.Value_f2) savedArrObj.Value_f2 = t?.Value_f2;
          if (t?.Value_f3) savedArrObj.Value_f3 = t?.Value_f3;
          if (t?.Value_f4) savedArrObj.Value_f4 = t?.Value_f4;
          if (t?.Value_f5) savedArrObj.Value_f5 = t?.Value_f5;
          // if (data.all_data.f_fieldtype === "datetime") {
          //   setDatePickerYYYY(savedArr[0].Value ? savedArr[0].Value : "");
          //   setDatePickerMM(savedArr[0].Value_f2 ? savedArr[0].Value_f2 : "");
          //   setDatePickerDD(savedArr[0].Value_f3 ? savedArr[0].Value_f3 : "");
          //   setDatePickerYY(savedArr[0].Value_f4 ? savedArr[0].Value_f4 : "");
          //   setDatePickerMMMM(savedArr[0].Value_f5 ? savedArr[0].Value_f5 : "");
          // }
        }
      });
    }
    let k = [
      {
        f_name: "f_name",
        f_label: f.f_label,
        f_used: true,
        f_value: savedArrObj.Value ? savedArrObj.Value : f.f_value,
        special_action: f?.special_action_address
          ? "special_action_address"
          : f?.special_action_closedate
          ? "special_action_closedate"
          : f?.special_action_party
          ? "special_action_party"
          : null,
      },
    ];
    if (f.f2_used) {
      k.push({
        f_name: "f2_name",
        f_label: f.f2_label,
        f_used: f.f2_used,
        f_value: savedArrObj.Value_f2 ? savedArrObj.Value_f2 : f.f2_value,
        special_action: f?.special_action_address
          ? "special_action_address"
          : f?.special_action_closedate
          ? "special_action_closedate"
          : f?.special_action_party
          ? "special_action_party"
          : null,
      });
    }
    if (f.f3_used) {
      k.push({
        f_name: "f3_name",
        f_label: f.f3_label,
        f_used: f.f3_used,
        f_value: savedArrObj.Value_f3 ? savedArrObj.Value_f3 : f.f3_value,
        special_action: f?.special_action_address
          ? "special_action_address"
          : f?.special_action_closedate
          ? "special_action_closedate"
          : f?.special_action_party
          ? "special_action_party"
          : null,
      });
    }
    if (f.f4_used) {
      k.push({
        f_name: "f4_name",
        f_label: f.f4_label,
        f_used: f.f4_used,
        f_value: savedArrObj.Value_f4 ? savedArrObj.Value_f4 : f.f4_value,
        special_action: f?.special_action_address
          ? "special_action_address"
          : f?.special_action_closedate
          ? "special_action_closedate"
          : f?.special_action_party
          ? "special_action_party"
          : null,
      });
    }
    if (f.f5_used) {
      k.push({
        f_name: "f5_name",
        f_label: f.f5_label,
        f_used: f.f5_used,
        f_value: savedArrObj.Value_f5 ? savedArrObj.Value_f5 : f.f5_value,
        // savedArr && savedArr?.length > 0 ? savedArr[0].Value_f5 : f.f5_value,
        special_action: f?.special_action_address
          ? "special_action_address"
          : f?.special_action_closedate
          ? "special_action_closedate"
          : f?.special_action_party
          ? "special_action_party"
          : null,
      });
    }
    const inV = {};
    const schema = {};

    k.map((f) => {
      inV[f.f_name] = f.f_value ? f.f_value : "";
      inV.special_action = f.special_action;
    });
    // k.map((f) => (schema[f.f_name] = Yup.string().required("Required")));
    setFieldsInitValues({ ...inV });
    setFieldsSchemaObj({ ...schema });
    setFields([...k]);
  };

  useEffect(() => {
    getFormFields(data.all_data);
  }, []);

  useEffect(() => {
    if (isFeildDatePickerOpened && data.all_data.f_fieldtype === "datetime") {
      setIsDatePiker(true);
    } else {
      setIsDatePiker(false);
    }
  }, [isFeildDatePickerOpened]);

  useEffect(() => {
    if (formData) {
      // console.log(formData, "formData");
      dispatch(addFormsData(formData));
      dispatch(addAllWorkFormsData(allFormData));
      dispatch(setIsFormOpened(false));
    }
  }, [formData]);

  return (
    fields &&
    fieldsInitValues &&
    fieldsSchemaObj && (
      <div className="FieldForm-wrapper">
        <div className="FieldForm-wrapper-content">
          <div className="FieldForm-content-close" onClick={onCloseHandler}>
            <CloseIcon color={colors.mainBg} width={20} height={20} />
          </div>
          {isDatePiker && (
            <div className="FieldForm-content-datePicker">
              <DatePicker
                options={{
                  dateFormat: "m-d-Y",
                  position: "auto right",
                }}
                onChange={handleDateForm}
                datePickerRef={datePickerRef}
                flatpickrInstance={flatpickrInstance}

                // initialValue={dateFrom ? new Date(dateFrom) : null}
              />
            </div>
          )}
          <div className="FieldForm-content-form">
            <Formik
              initialValues={fieldsInitValues}
              validationSchema={Yup.object().shape({
                ...fieldsSchemaObj,
              })}
              onSubmit={(values, { setSubmitting }) => {
                // alert(JSON.stringify(values, null, 2));
                onSubmitHandler(values);
                setSubmitting(false);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="FieldForm-content-form-wrapper">
                    {data?.all_data?.friendlyname &&
                      data?.all_data?.friendlyname !== "null" && (
                        <div className="FieldForm-content-form-title-wrapper">
                          {data?.all_data?.friendlyname}
                        </div>
                      )}
                    {fields?.map((f, index) => {
                      if (isDatePiker) {
                        if (f.f_name === "f_name") {
                          values.f_name = datePickerYYYY;
                        }
                        if (f.f_name === "f2_name") {
                          values.f2_name = datePickerMM;
                        }
                        if (f.f_name === "f3_name") {
                          values.f3_name = datePickerDD;
                        }
                        if (f.f_name === "f4_name") {
                          values.f4_name = datePickerYY;
                        }
                        if (f.f_name === "f5_name") {
                          values.f5_name = datePickerMMMM;
                        }
                      }
                      return (
                        <div
                          className="FieldForm-content-form-input-wrapper"
                          key={index}
                        >
                          <BaseInput
                            label={f.f_label}
                            type="text"
                            name={f.f_name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values[f.f_name] ? values[f.f_name] : ""}
                            // placeholder="placeholder"
                            required={
                              errors[f.f_name] &&
                              touched[f.f_name] &&
                              errors[f.f_name]
                            }
                            // no_borders={true}
                            disabled={isDatePiker ? true : false}
                          />

                          <div className="FieldForm-content-form-error-name">
                            {errors[f.f_name] &&
                              touched[f.f_name] &&
                              errors[f.f_name]}
                          </div>
                        </div>
                      );
                    })}
                    <div className="FieldForm-content-form-btns-wrapper">
                      <BaseButton
                        type="submit"
                        disabled={isSubmitting}
                        className="FieldForm-content-form-btns-submit"
                      >
                        OK
                      </BaseButton>
                      <BaseButton
                        onClick={onCancelHandler}
                        className="FieldForm-content-form-btns-cancel"
                      >
                        CANCEL
                      </BaseButton>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    )
  );
}

export default FieldForm;
