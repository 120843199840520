import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { colors } from "../../constants/colors";
import { ArrowUpIcon } from "../../constants/icons";
import LeftTitleBlock from "../LeftTitleBlock/LeftTitleBlock";
import { addReducedForms } from "../../store/actions/addReducedForms";
import { removeReducedForms } from "../../store/actions/removeReducedForms";
import "./LeftMainBlock.css";
import "./responsive.css";

function LeftMainBlock({
  setCurrentItem,
  setCurrentPage,
  currentPage,
  currentItem,
  setPrevPdf,
  isExpandedAll,
  isArrowsBlockClicked,
  setIsArrowsBlockClicked,
  setLeftBarOpened,
  leftBarOpened,
  initView,
  multipleFormData,
  setMultipleFormData,
  isNarrowToggle,
  formsResult,
  setFormsResult,
  isDragReorder,
  setIsDragReorder,
  isReducedTitle,
  setIsReducedTitle,
}) {
  const [isExpanded, setExpanded] = useState(false);
  const [isExpandedForm, setIsExpandedForm] = useState(false);
  const dispatch = useDispatch();

  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [dragging, setDragging] = useState(null);
  const [rel, setRel] = useState({
    bounds: {
      formId: null,
    },
    x: 0,
    y: 0,
  });
  const [divRef, setDivRef] = useState(null);
  const [currentMouseForm, setCurrentMouseForm] = useState(null);

  // const { form } = useSelector((state) => state.formToRemove);
  const { formsReduced } = useSelector((state) => state.formsReduced);

  // useEffect(() => {
  //   console.log(formsReduced, "formsReduced");
  // }, [formsReduced]);

  const { removeAllForms } = useSelector((state) => state.allFormsToRemove);

  useEffect(() => {
    if (removeAllForms) {
      setFormsResult([]);
    }
  }, [removeAllForms]);

  // useEffect(() => {
  //   console.log(formsResult, "formsResult");
  // }, [formsResult]);

  useEffect(() => {
    if (isExpandedForm?.isExpanded) {
      dispatch(addReducedForms({ formId: isExpandedForm?.formId }));
    }
    if (isExpandedForm?.isExpanded === false && formsReduced?.length > 0) {
      dispatch(removeReducedForms({ formId: isExpandedForm?.formId }));
    }
  }, [isExpandedForm]);

  useEffect(() => {
    if (formsResult?.length > 0 && currentPage === null) {
      let firstItem = document.getElementsByClassName(
        "LeftTitleBlock-list-item"
      )[0];
      firstItem.style.backgroundColor = colors.blue;
      firstItem.style.color = colors.white;
      setPrevPdf(firstItem);
    }
  }, [currentPage]);

  const leftOpenedHandler = () => {
    setLeftBarOpened(!leftBarOpened);
  };

  useEffect(() => {
    if (dragging === false && multipleFormData?.length > 0) {
      //TODO TODO TODO TODOTODOTODOTODOTODO
      // SET NEW BOUNDS with new position
      let temp_dragging_res = formsResult?.map((res, ind, res_arr) => {
        if (res.bounds.formId === rel.bounds.formId) {
          const copy = Object.assign({}, res);
          copy.bounds = {
            formId: rel.bounds.formId,
            boundY: rel.y,
          };
          return copy;
        } else return res;
      });

      // SORTED ALL BOUNDS AND RECALCULATE BOUNDS
      let temp_formsResult_recalc = temp_dragging_res
        .sort((a, b) => {
          return a?.bounds?.boundY - b?.bounds?.boundY;
        })
        .map((res, ind, res_arr) => {
          const FormID = res?.formValue?.data[0]?.FormID;
          let isPrevFormReduced = [];
          if (ind !== 0) {
            isPrevFormReduced = formsReduced.filter((f) => {
              const prevFormID = res_arr[ind - 1]?.formValue?.data[0]?.FormID;
              return f.formId === prevFormID;
            });
          }
          const copy = Object.assign({}, res);

          let init_boundY;
          if (ind !== 0) init_boundY = res_arr[ind - 1]?.bounds?.boundY;
          if (ind === 0) {
            copy.bounds = {
              formId: FormID,
              boundY: 0,
            };
          } else {
            copy.bounds = {
              formId: FormID,
              boundY:
                isPrevFormReduced?.length > 0
                  ? init_boundY + 28
                  : init_boundY + 28 + +res_arr[ind - 1]?.TotalPages * 23 + 8,
            };
          }
          return copy;
        });

      setMultipleFormData([...temp_formsResult_recalc]);
      setFormsResult([...temp_formsResult_recalc]);
    }
  }, [dragging]);

  useEffect(() => {
    if (formsResult?.length > 0) {
      // SORTED ALL BOUNDS AND RECALCULATE BOUNDS
      let temp_formsResult_reduce = multipleFormData.map(
        (res, ind, res_arr) => {
          const FormID = res?.formValue?.data[0]?.FormID;
          let isPrevFormReduced = [];
          if (ind !== 0) {
            isPrevFormReduced = formsReduced.filter((f) => {
              const prevFormID = res_arr[ind - 1]?.formValue?.data[0]?.FormID;
              return f.formId === prevFormID;
            });
          }
          let init_boundY;
          const copy = Object.assign({}, res);
          if (ind !== 0) init_boundY = res_arr[ind - 1]?.bounds?.boundY;
          if (ind === 0) {
            copy.bounds = {
              formId: FormID,
              boundY: 0,
            };
          } else {
            copy.bounds = {
              formId: FormID,
              boundY:
                isPrevFormReduced?.length > 0
                  ? init_boundY + 28
                  : init_boundY + 28 + +res_arr[ind - 1]?.TotalPages * 23 + 8,
            };
          }
          return copy;
        }
      );
      setFormsResult([...temp_formsResult_reduce]);
    }
  }, [formsReduced]);

  useEffect(() => {
    if (multipleFormData?.length > 0) {
      if (!formsResult || formsResult?.length === 0) {
        let temp_formsResult = multipleFormData.map((res, ind, res_arr) => {
          const FormID = res?.formValue?.data[0]?.FormID;
          let init_boundY;
          if (ind !== 0) init_boundY = res_arr[ind - 1]?.bounds?.boundY;
          if (ind === 0) {
            res.bounds = {
              formId: FormID,
              boundY: 0,
            };
          } else {
            res.bounds = {
              formId: FormID,
              boundY: init_boundY + 28 + +res_arr[ind - 1]?.TotalPages * 23 + 8,
            };
          }
          return res;
        });
        setFormsResult([...temp_formsResult]);
      }
      //if FORM was ADDED --> do!
      if (
        multipleFormData?.length > formsResult?.length &&
        formsResult?.length > 0
      ) {
        // console.log("filteredArray 2");
        if (formsResult?.length > 0) {
          const idsToFilter = new Set(formsResult?.map((item) => item.id));
          const addedArray = multipleFormData?.filter(
            (item) => !idsToFilter.has(item.id)
          );
          if (addedArray?.length > 0) {
            let temp_formsResult = multipleFormData.map((res, ind, res_arr) => {
              if (ind >= res_arr?.length - addedArray?.length) {
                const FormID = res?.formValue?.data[0]?.FormID;
                let init_boundY = res_arr[ind - 1]?.bounds?.boundY;
                res.bounds = {
                  formId: FormID,
                  // boundY: +init_boundY + 28 + +res?.TotalPages * 23 + 8,
                  boundY:
                    init_boundY + 28 + +res_arr[ind - 1]?.TotalPages * 23 + 8,
                };
              }
              return res;
            });

            setFormsResult([...temp_formsResult]);
          }
        }
      }
      //if FORM was removed --> do!
      if (
        multipleFormData?.length < formsResult?.length &&
        formsResult?.length > 0
      ) {
        const idsToFilter = new Set(multipleFormData.map((item) => item.id));
        const filteredArray = formsResult.filter((item) =>
          idsToFilter.has(item.id)
        );

        const removedArray = formsResult.filter(
          (item) => !idsToFilter.has(item.id)
        );

        const removedFormId = removedArray[0]?.formValue?.data[0]?.FormID;
        let last_filteredArray = filteredArray.map((res, ind, res_arr) => {
          const FormID = res?.formValue?.data[0]?.FormID;
          let init_boundY;
          if (ind !== 0) init_boundY = res_arr[ind - 1]?.bounds?.boundY;
          if (ind === 0) {
            res.bounds = {
              formId: FormID,
              boundY: 0,
            };
          } else {
            res.bounds = {
              formId: FormID,
              boundY: init_boundY + 28 + +res_arr[ind - 1]?.TotalPages * 23 + 8,
            };
          }
          return res;
        });
        if (removedFormId) {
          dispatch(removeReducedForms({ formId: removedFormId }));
        }
        // setMultipleFormData([...filteredArray]);
        setFormsResult([...last_filteredArray]);
      }
    }
  }, [multipleFormData]);

  //Touch Events: If you want to support touch devices, you'll need to add similar event handlers for touch events (onTouchStart, onTouchMove, onTouchEnd).

  const onMouseDown = (e) => {
    const wrWidth = document.querySelectorAll(
      ".LeftTitleBlock-header-wrapper"
    )[0]?.offsetWidth;
    if (e.button !== 0) return; // Only left mouse button
    setPosition({
      x: e.pageX - wrWidth + 14,
      y: e.pageY - 80,
    });
    setDragging(true);
    e.stopPropagation();
    e.preventDefault();
  };

  const onMouseMove = (e) => {
    if (dragging === false) return;
    const wrWidth = document.querySelectorAll(
      ".LeftTitleBlock-header-wrapper"
    )[0]?.offsetWidth;
    setPosition({
      x: e.pageX - wrWidth + 14,
      y: e.pageY - 80,
    });
    e.stopPropagation();
    e.preventDefault();
  };

  const onMouseUp = (e) => {
    if (dragging === true) {
      setDragging(false);
      e.stopPropagation();
      e.preventDefault();
      const wrWidth = document.querySelectorAll(
        ".LeftTitleBlock-header-wrapper"
      )[0]?.offsetWidth;
      setRel({
        bounds: {
          formId: currentMouseForm?.formValue?.data[0]?.FormID,
        },
        x: e.pageX - wrWidth + 14,
        y: e.pageY - 80,
      });
    }
  };

  const onMouseLeave = (e) => {
    if (dragging === true) {
      setDragging(false);
      e.stopPropagation();
      e.preventDefault();
    }
  };

  return (
    <div
      className="LeftMainBlock-wrapper"
      onMouseUp={onMouseUp}
      onMouseLeave={onMouseLeave}
      onMouseMove={onMouseMove}
      //for touchScreen
      // onTouchStart
      onTouchMove={onMouseMove}
      onTouchEnd={onMouseUp}
    >
      <div className="LeftMainBlock-main-wrapper">
        {multipleFormData?.length > 0 &&
          formsResult?.length > 0 &&
          formsResult.map((item, index) => {
            return (
              <LeftTitleBlock
                headerNum={item?.FormNum}
                headerDesc={item?.FormName}
                totalPages={item?.TotalPages}
                key={index}
                item={item}
                onClick={setCurrentItem}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                isExpandedAll={isExpandedAll}
                isArrowsBlockClicked={isArrowsBlockClicked}
                setIsArrowsBlockClicked={setIsArrowsBlockClicked}
                isFirstPdfBlock={index === 0 ? true : false}
                multipleFormData={multipleFormData}
                setMultipleFormData={setMultipleFormData}
                isNarrowToggle={isNarrowToggle}
                divRef={divRef}
                setDivRef={setDivRef}
                onMouseDown={onMouseDown}
                setCurrentMouseForm={setCurrentMouseForm}
                position={position}
                isExpanded={isExpanded}
                setExpanded={setExpanded}
                setIsExpandedForm={setIsExpandedForm}
                dragging={dragging}
                isDragReorder={isDragReorder}
                setIsDragReorder={setIsDragReorder}
                isReducedTitle={isReducedTitle}
                setIsReducedTitle={setIsReducedTitle}
              />
            );
          })}
      </div>
      {!initView && (
        <div
          className={
            leftBarOpened
              ? "MainBlock-wrapper-left-open-btn MainBlock-wrapper-left-open-btn-opened"
              : "MainBlock-wrapper-left-open-btn MainBlock-wrapper-left-open-btn-closed"
          }
          onClick={leftOpenedHandler}
        >
          <div
            className={
              leftBarOpened
                ? "MainBlock-wrapper-left-open-btn-wrapper MainBlock-wrapper-left-open-btn-wrapper-opened"
                : "MainBlock-wrapper-left-open-btn-wrapper MainBlock-wrapper-left-open-btn-wrapper-closed"
            }
          >
            <div
              className={
                leftBarOpened
                  ? "MainBlock-wrapper-left-open-btn-content MainBlock-wrapper-left-open-btn-content-left"
                  : "MainBlock-wrapper-left-open-btn-content MainBlock-wrapper-left-open-btn-content-right"
              }
            >
              <ArrowUpIcon color={colors.mainBg} width={24} height={14} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default LeftMainBlock;
