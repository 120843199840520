import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { colors } from "../../constants/colors";
import {
  CheckBoxActiveIcon,
  CheckBoxIcon,
  CloseIcon,
} from "../../constants/icons";
import { setFormsToDownloadPopUp } from "../../store/actions/setFormsToDownloadPopUp";
import "./DownloadPopUp.css";
import "./responsive.css";

function DownloadPopUp({
  data,
  onConfirm,
  onCancel,
  headerColor = false,
  contentColor = false,
  header = "What do you want to download?",
}) {
  const { formsToDownload } = useSelector((state) => state.downloadForms);

  // useEffect(() => {
  //   console.log(formsToDownload, "formsToDownload");
  // }, [formsToDownload]);

  const [isAllChecked, setIsAllChecked] = useState(false);

  return (
    <div className="download-popup-wrapper">
      <div className="download-popup-close" onClick={onCancel}>
        <CloseIcon width={24} height={24} color={colors.blue} />
      </div>
      <div className="download-popup-content">
        <div className="download-popup-content-header">
          <span style={{ color: headerColor ? headerColor : colors.mainBg }}>
            {header}
          </span>
        </div>
        <DownloadAllFormBlock
          isChecked={isAllChecked}
          setIsChecked={setIsAllChecked}
          data={data}
        />
        <div className="download-popup-content-text-wrapper">
          {data.map((form, ind) => (
            <FormBlock
              key={ind}
              id={ind}
              form={form}
              contentColor={contentColor}
              isAllChecked={isAllChecked}
              setIsAllChecked={setIsAllChecked}
            />
          ))}
        </div>
      </div>
      <div className="download-popup-btns-wrapper">
        <div
          className={
            formsToDownload?.length > 0
              ? "download-popup-btn-confirm download-popup-btn-block"
              : "download-popup-btn-confirm-disabled download-popup-btn-block"
          }
          onClick={formsToDownload?.length > 0 ? onConfirm : null}
        >
          Apply
        </div>
        <div
          className="download-popup-btn-cansel download-popup-btn-block"
          onClick={onCancel}
        >
          Cancel
        </div>
      </div>
    </div>
  );
}

export default DownloadPopUp;

const FormBlock = ({
  form,
  id,
  contentColor,
  isAllChecked,
  setIsAllChecked,
}) => {
  const dispatch = useDispatch();
  const { formsToDownload } = useSelector((state) => state.downloadForms);
  const [isChecked, setIsChecked] = useState(false);

  const checkBoxHandler = () => {
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    if (isAllChecked) {
      setIsChecked(true);
    }
  }, [isAllChecked]);

  useEffect(() => {
    let tempIsAllChecked = true;
    if (isAllChecked && !isChecked) {
      setIsAllChecked(false);
      tempIsAllChecked = false;
    }
    let formValueID = form?.formValue?.data[0]?.FormID;
    if (!isAllChecked || !tempIsAllChecked) {
      if (isChecked) {
        if (formsToDownload?.length > 0) {
          dispatch(
            setFormsToDownloadPopUp([
              ...formsToDownload,
              { FormID: formValueID },
            ])
          );
        } else {
          dispatch(setFormsToDownloadPopUp([{ FormID: formValueID }]));
        }
      } else {
        if (formsToDownload?.length > 0) {
          dispatch(
            setFormsToDownloadPopUp([
              ...formsToDownload.filter((f) => f.FormID !== formValueID),
            ])
          );
        }
      }
    }
  }, [isChecked]);

  return (
    <div className="download-popup-content-wrapper" key={id}>
      <div
        className="download-popup-checkbox-wrapper"
        onClick={checkBoxHandler}
      >
        {isChecked ? (
          <CheckBoxActiveIcon width={17} height={17} color={colors.mainBg} />
        ) : (
          <CheckBoxIcon width={17} height={17} color={colors.mainBg} />
        )}
      </div>
      <div className="download-popup-content-content-wrapper">
        <span
          style={{
            color: contentColor ? contentColor : colors.mainBg,
          }}
        >
          {form.FormNum}{" "}
        </span>
        <span
          style={{
            color: contentColor ? contentColor : colors.mainBg,
          }}
        >
          {form.FormName}
        </span>
      </div>
    </div>
  );
};

const DownloadAllFormBlock = ({
  contentColor,
  isChecked,
  setIsChecked,
  data,
}) => {
  const dispatch = useDispatch();

  const checkBoxHandler = () => {
    if (!isChecked) setIsChecked(true);
  };

  useEffect(() => {
    if (isChecked) {
      const temp = [];
      data.map((form) => {
        let formValueID = form?.formValue?.data[0]?.FormID;
        temp.push({ FormID: formValueID });
      });
      dispatch(setFormsToDownloadPopUp([...temp]));
    }
  }, [isChecked]);

  return (
    <div className="download-popup-content-wrapper download-popup-content-wrapper-select-all">
      <div
        className="download-popup-checkbox-wrapper"
        onClick={checkBoxHandler}
      >
        {isChecked ? (
          <CheckBoxActiveIcon width={17} height={17} color={colors.mainBg} />
        ) : (
          <CheckBoxIcon width={17} height={17} color={colors.mainBg} />
        )}
      </div>
      <div className="download-popup-content-content-wrapper">
        <span
          style={{
            color: contentColor ? contentColor : colors.mainBg,
          }}
        >
          Select All
        </span>
      </div>
      <div className="download-popup-separator" />
    </div>
  );
};
