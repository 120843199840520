import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpenConfirmPopUp: null,
  removeAllForms: null,
};

export const allFormsToRemoveSlice = createSlice({
  name: "allFormsToRemove",
  initialState,
  reducers: {
    setIsOpenConfirmPopUp(state, actions) {
      state.isOpenConfirmPopUp = actions.payload;
    },
    setRemoveAllForms(state, actions) {
      state.removeAllForms = actions.payload;
    },
  },
});

export default allFormsToRemoveSlice.reducer;
