import React, { createContext, useState, useContext } from "react";

// Create a Context
const IsAccessTokenContext = createContext();

// Create a Provider component
export const IsAccessTokenProvider = ({ children }) => {
  const [tokenExpired, setTokenExpired] = useState(false);

  return (
    <IsAccessTokenContext.Provider value={{ tokenExpired, setTokenExpired }}>
      {children}
    </IsAccessTokenContext.Provider>
  );
};

// Custom hook to use the IsAccessTokenContext
export const useIsAccessToken = () => {
  return useContext(IsAccessTokenContext);
};
