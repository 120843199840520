import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpenPrintPopUp: null,
  formsToPrint: null,
  isConfirmToPrint: null,
};

export const printFormsSlice = createSlice({
  name: "printFormsSlice",
  initialState,
  reducers: {
    setIsOpenPrintPopUp(state, actions) {
      state.isOpenPrintPopUp = actions.payload;
    },
    setPrintForms(state, actions) {
      state.formsToPrint = actions.payload;
    },
    setIsConfirmToPrintPopUp(state, actions) {
      state.isConfirmToPrint = actions.payload;
    },
  },
});

export default printFormsSlice.reducer;
