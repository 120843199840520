import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FieldForm, MainHeaderBlock } from "../../components";
import { colors } from "../../constants/colors";
import { CloseIcon } from "../../constants/icons";
import { useAuth, useScreenSize } from "../../hooks";
import { MainBlock } from "../../modules";
import { resetAllWorkFormsData } from "../../store/actions/resetAllWorkFormsData";
import { resetFormsData } from "../../store/actions/resetFormsData";
import "./MainPage.css";
import "./responsive.css";
import { useIsAccessToken } from "../../context/IsAccessTokenContext";
import { isTokenExpired } from "../../utils/tokenExpiration";

function MainPage({ accessToken, options, setCurrentToken, currentToken }) {
  const { userExpiresIn, userRefreshToken, clearToken, setToken, userToken } =
    useAuth();
  const { tokenExpired, setTokenExpired } = useIsAccessToken();

  const dispatch = useDispatch();
  const [currentItem, setCurrentItem] = useState(null);
  const [isExpandedAll, setIsExpandedAll] = useState(null);
  const [isArrowsBlockClicked, setIsArrowsBlockClicked] = useState(null);
  const [multipleFormData, setMultipleFormData] = useState([]);
  const [allFormData, setAllFormData] = useState(null);
  const [isNarrowToggle, setIsNarrowToggle] = useState(true);

  const [initView, setInitView] = useState(true);

  const [userData, setUserData] = useState(null);
  const [isUserDataLoading, setIsUserDataLoading] = useState(null);

  const [selectedForms, setSelectedForms] = useState([]);
  const [currentList, setCurrentList] = useState([]);
  const [isFormsListVisible, setIsFormsListVisible] = useState(false);

  const resetAllData = () => {
    setCurrentItem(null);
    setIsExpandedAll(null);
    setIsArrowsBlockClicked(null);
    setAllFormData(null);
    setIsNarrowToggle(true);
    dispatch(resetAllWorkFormsData());
    dispatch(resetFormsData());
  };

  useEffect(() => {
    if (multipleFormData?.length > 0 && initView) {
      setInitView(false);
    }
    if (multipleFormData?.length === 0) {
      resetAllData();
    }
  }, [multipleFormData?.length]);

  // const { isFeildFormOpened } = useSelector((state) => state.isFormOpened);
  useEffect(() => {
    getUserData();
  }, []);

  const refreshAccessTokenUserData = () => {
    const body = { refresh_token: userRefreshToken };
    axios
      .post(`https://api.realtytexas.net/v1/clients/web/refresh`, body)
      .then(async (res) => {
        await clearToken();

        if (res?.data?.access_token) {
          setToken({
            access_token: res?.data?.access_token,
            refresh_token: res?.data?.refresh_token,
            expires_in: res?.data?.expires_in,
          });
        }

        const token = res?.data?.access_token;
        const config = {
          headers: { Authorization: `Bearer ${token}` },
        };
        return axios
          .get(`https://api.realtytexas.net/v1/users/me`, config)
          .then(function (response) {
            setUserData(response.data);
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch(function (error) {
        clearToken();
        setTokenExpired(false);
        console.log(error?.response?.data?.message);
      });
  };

  const getUserData = () => {
    setIsUserDataLoading(true);
    // const token = accessToken;
    let isExpired = false;
    if (!currentToken) {
      isExpired = isTokenExpired(userExpiresIn);
    }
    if (isExpired) {
      refreshAccessTokenUserData();
    } else {
      const token = currentToken ? currentToken : userToken;
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      axios
        .get(`https://api.realtytexas.net/v1/users/me`, config)
        .then(function (response) {
          setUserData(response.data);
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(function () {
          setIsUserDataLoading(false);
        });
    }
  };

  const screenSize = useScreenSize();

  return (
    <div className="MainPage-wrapper">
      {/* {isFeildFormOpened && <FieldForm />} */}
      <MainHeaderBlock
        currentItem={currentItem}
        isExpandedAll={isExpandedAll}
        setIsExpandedAll={setIsExpandedAll}
        isArrowsBlockClicked={isArrowsBlockClicked}
        setIsArrowsBlockClicked={setIsArrowsBlockClicked}
        multipleFormData={multipleFormData}
        setMultipleFormData={setMultipleFormData}
        allFormData={allFormData}
        setAllFormData={setAllFormData}
        initView={initView}
        accessToken={accessToken}
        isNarrowToggle={isNarrowToggle}
        setIsNarrowToggle={setIsNarrowToggle}
        userData={userData}
        currentToken={currentToken}
        setCurrentToken={setCurrentToken}
        selectedForms={selectedForms}
        setSelectedForms={setSelectedForms}
        currentList={currentList}
        setCurrentList={setCurrentList}
        isFormsListVisible={isFormsListVisible}
        setIsFormsListVisible={setIsFormsListVisible}
      />
      {screenSize[0] <= 768 && (
        <SelectedForms
          selectedForms={selectedForms}
          setSelectedForms={setSelectedForms}
          multipleFormData={multipleFormData}
          setMultipleFormData={setMultipleFormData}
          currentList={currentList}
          setCurrentList={setCurrentList}
        />
      )}
      {multipleFormData && (
        <MainBlock
          currentItem={currentItem}
          setCurrentItem={setCurrentItem}
          isExpandedAll={isExpandedAll}
          setIsExpandedAll={setIsExpandedAll}
          isArrowsBlockClicked={isArrowsBlockClicked}
          setIsArrowsBlockClicked={setIsArrowsBlockClicked}
          multipleFormData={multipleFormData}
          initView={initView}
          setMultipleFormData={setMultipleFormData}
          options={options}
          isNarrowToggle={isNarrowToggle}
          setIsNarrowToggle={setIsNarrowToggle}
          userData={userData}
          selectedForms={selectedForms}
          setSelectedForms={setSelectedForms}
          isFormsListVisible={isFormsListVisible}
          setIsFormsListVisible={setIsFormsListVisible}
        />
      )}
    </div>
  );
}

export default MainPage;

const SelectedForms = ({
  selectedForms,
  setSelectedForms,
  multipleFormData,
  setMultipleFormData,
  currentList,
  setCurrentList,
}) => {
  const removeSelectedItem = (data) => {
    if (data?.id) {
      setTimeout(
        () =>
          setSelectedForms([
            ...selectedForms.filter((el) => el.id !== data.id),
          ]),
        0
      );

      setMultipleFormData(
        [...multipleFormData]
          .filter((m) => data?.id !== m.id)
          .sort(
            (a, b) =>
              // a.FormName.localeCompare(b.FormName)
              a?.bounds?.boundY - b?.bounds?.boundY
          )
      );

      setCurrentList([...currentList, data]);
    }
  };
  return (
    <div className="SelectedForms-wrapper">
      {selectedForms.length > 0 &&
        selectedForms
          .sort((a, b) => a.id - b.id)
          .map((el, i) => {
            return (
              <div key={i} className="SelectedForms-wrapper-el">
                <div className="baseInput-selected-wrapper-el-title">
                  {el.FormNum}
                </div>
                <div
                  className="baseInput-selected-wrapper-el-close"
                  onClick={() => removeSelectedItem(el)}
                >
                  <CloseIcon height={10} width={10} color={colors.blue} />
                </div>
              </div>
            );
          })}
    </div>
  );
};
