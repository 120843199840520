import React, { createContext, useState, useContext } from "react";

// Create a Context
const AppContext = createContext();

// Create a Provider component
export const AppProvider = ({ children }) => {
  const [renderTrigger, setRenderTrigger] = useState(0);

  const triggerRerender = () => {
    setRenderTrigger(renderTrigger + 1);
  };

  return (
    <AppContext.Provider value={{ triggerRerender, renderTrigger }}>
      {children}
    </AppContext.Provider>
  );
};

// Custom hook to use the AppContext
export const useApp = () => {
  return useContext(AppContext);
};
