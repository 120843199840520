import React, { useEffect, useState } from "react";
import { colors } from "../../constants/colors";
import { scrollToSmoothly } from "../../utils/waitForElm";
import { createRoot } from "react-dom/client";
import "./RightMainBlock.css";
import { useDispatch, useSelector } from "react-redux";
import FieldBlock from "../FieldBlock/FieldBlock";
import FieldForm from "../FieldForm/FieldForm";
import { addFormsData } from "../../store/actions/addFormsData";

import BottomTitleBlock from "../BottomTitleBlock/BottomTitleBlock";
import { useScreenSize } from "../../hooks";

function RightMainBlock({
  docs,
  currentPage,
  currentItem,
  setCurrentItem,
  prevPdf,
  setPrevPdf,
  multipleFormData,
  leftBarOpened,
  removeForm,
  isNarrowToggle,
  userData,
  isDragReorder,
  setIsDragReorder,
  isFormsListVisible,
  setIsFormsListVisible,
}) {
  let pageWidthPT = "612";
  let pageHeightPT = "792";

  const screenWidth = useScreenSize();

  const dispatch = useDispatch();
  const [currentFieldData, setCurrentFieldData] = useState(null);

  /////// handleTabPress FUNCTIONALITY!!!! START! SECOND PART
  const [inputWrappers, setInputWrappers] = useState([]);
  const [inputWrappersClassObj, setInputWrappersClassObj] = useState(null);
  const [inputWrapperFocused, setInputWrapperFocused] = useState(null);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const handleTabPressForm = (event) => {
    if (event.key === "Tab" || (event.shiftKey && event.keyCode === 9)) {
      event.preventDefault();
      return null;
    }
  };

  const handleTabPress = (event) => {
    if (inputWrappersClassObj?.length > 0 && inputWrapperFocused?.wrapperID) {
      // console.log(inputWrappersClassObj, "inputWrappersClassObj");
      if (
        (event.key === "Tab" || (event.shiftKey && event.keyCode === 9)) &&
        inputWrappersClassObj?.length > 0
      ) {
        event.preventDefault();
        let nextWrInd = 0;
        inputWrappersClassObj?.map((wr, ind, wr_arr) => {
          if (ind !== wr_arr.length) {
            if (wr?.wrapperID === inputWrapperFocused?.wrapperID) {
              nextWrInd = ind;
            }
          } else {
            if (wr?.wrapperID === inputWrapperFocused?.wrapperID) {
              nextWrInd = 0;
            }
          }
        });
        let nextWr;
        if (event.key === "Tab") {
          if (inputWrappersClassObj[nextWrInd + 1]) {
            nextWr = inputWrappersClassObj[nextWrInd + 1];
          } else {
            nextWr = inputWrappersClassObj[0];
          }
        }
        if (event.shiftKey && event.keyCode === 9) {
          if (inputWrappersClassObj[nextWrInd - 1]) {
            nextWr = inputWrappersClassObj[nextWrInd - 1];
          } else {
            nextWr = inputWrappersClassObj[inputWrappersClassObj?.length - 1];
          }
        }
        const tempCurrent = inputWrappersClassObj[nextWrInd];
        let docCurrent;

        if (
          tempCurrent.type === "textfield" ||
          tempCurrent.type === "currency" ||
          tempCurrent.type === "number" ||
          tempCurrent.type === "datetime"
        ) {
          docCurrent = document
            .getElementById(tempCurrent.wrapperID)
            .querySelectorAll("input");
        } else if (tempCurrent.type === "textarea") {
          docCurrent = document
            .getElementById(tempCurrent.wrapperID)
            .querySelectorAll("textarea")[0];
        } else {
          docCurrent = document
            .getElementById(tempCurrent.wrapperID)
            .querySelectorAll("svg")[0];
        }
        let doc;
        if (
          nextWr.type === "textfield" ||
          nextWr.type === "currency" ||
          nextWr.type === "number" ||
          nextWr.type === "datetime"
        ) {
          doc = document
            .getElementById(nextWr.wrapperID)
            .querySelectorAll("input")[0];
        } else if (nextWr.type === "textarea") {
          doc = document
            .getElementById(nextWr.wrapperID)
            .querySelectorAll("textarea")[0];
        } else {
          doc = document
            .getElementById(nextWr.wrapperID)
            .querySelectorAll("svg")[0];
        }

        if (doc && docCurrent) {
          doc?.focus();
          // console.log(docCurrent, "querySelectorAll nextWr.wrapperID", doc);
        }
      }
    }
  };

  useEffect(() => {
    if (inputWrappersClassObj?.length > 0 && inputWrapperFocused?.wrapperID) {
      document.addEventListener(
        "keydown",
        !isFeildFormOpened && !isFormsListVisible
          ? handleTabPress
          : !isFormsListVisible
          ? handleTabPressForm
          : null
      );
      // Function to remove the event listener
      return () => {
        document.removeEventListener(
          "keydown",
          !isFeildFormOpened && !isFormsListVisible
            ? handleTabPress
            : !isFormsListVisible
            ? handleTabPressForm
            : null
        );
      };
    }
  }, [inputWrappersClassObj, inputWrapperFocused]);

  useEffect(() => {
    if (inputWrappersClassObj?.length > 0) {
      // Define the event handler
      const handleFocus = (event) => {
        const wrapper = event.target.closest(
          ".FieldBlock-fieldBlock-input-content"
        );
        if (wrapper) {
          setInputWrapperFocused({ wrapperID: wrapper.id });
        }
      };

      // Select all input elements inside elements with the class 'input-wrapper'
      const rightBlock = document.getElementById("RightMainBlock-wrapper");
      const inputs = rightBlock.querySelectorAll("input");
      const textareas = rightBlock.querySelectorAll("textarea");
      const checkboxes = rightBlock.querySelectorAll("svg");

      // Add focus event listener to each input element
      inputs.forEach((input) => {
        input.addEventListener("focus", handleFocus);
      });

      textareas.forEach((textarea) => {
        textarea.addEventListener("focus", handleFocus);
      });

      checkboxes.forEach((checkbox) => {
        checkbox.addEventListener("focus", handleFocus);
      });

      // Cleanup function to remove event listeners
      return () => {
        inputs.forEach((input) => {
          input.removeEventListener("focus", handleFocus);
        });
        textareas.forEach((textarea) => {
          textarea.removeEventListener("focus", handleFocus);
        });
        checkboxes.forEach((checkbox) => {
          checkbox.removeEventListener("focus", handleFocus);
        });
      };
    }
  }, [inputWrappersClassObj]);

  useEffect(() => {
    if (inputWrappers?.length > 0) {
      const temp = inputWrappers.map((d) => {
        let f = d.formValuesDisplayed.data[0];
        if (
          d.f_fieldtype === "textfield" ||
          d.f_fieldtype === "datetime" ||
          d.f_fieldtype === "currency" ||
          d.f_fieldtype === "number"
        ) {
          return {
            wrapperID: `fieldBlock-input-content-${f?.FormValuesID}-displayed-${f?.FormValuesDisplayedID}`,
            type: d.f_fieldtype,
          };
        } else {
          if (d.f_fieldtype === "textarea") {
            return {
              wrapperID: `fieldBlock-textarea-content-${f?.FormValuesID}-displayed-${f?.FormValuesDisplayedID}`,
              type: d.f_fieldtype,
            };
          } else {
            if (d.f_fieldtype === "checkbox") {
              return {
                wrapperID: `fieldBlock-checkbox-content-${f?.FormValuesID}-displayed-${f?.FormValuesDisplayedID}`,
                type: d.f_fieldtype,
              };
            }
          }
        }
      });
      setInputWrappersClassObj([...temp]);
    }
  }, [inputWrappers]);

  /////// handleTabPress FUNCTIONALITY!!!! END! SECOND PART

  // const { form } = useSelector((state) => state.formToRemove);
  const allWorkedFormDataObj = useSelector(
    (state) => state.allWorkFormsData.data
  );
  const formDataObj = useSelector((state) => state.formsData.data);
  const allFormDataObj = useSelector((state) => state.allWorkFormsData.data);

  const toAddressFormatt = (v, v2, v3, v4, v5) => {
    let temp_v = "";
    let temp_v2 = "";
    let temp_v3 = "";
    let temp_v4 = "";
    let temp_v5 = "";
    if (v && v !== null) temp_v = v;
    if (v2 && v2 !== null) temp_v2 = v2;
    if (v3 && v3 !== null) temp_v3 = v3;
    if (v4 && v4 !== null) temp_v4 = v4;
    if (v5 && v5 !== null) temp_v5 = v5;
    let str = temp_v;
    if (v2) str = str + " " + temp_v2;
    if (v3) str = str + " " + temp_v3;
    if (v4) str = str + " " + temp_v4;
    if (v5) str = str + " " + temp_v5;
    return str;
  };

  const toAddressFormattSomeFields = (
    v,
    v2,
    v3,
    v4,
    v5,
    dv,
    dv2,
    dv3,
    dv4,
    dv5
  ) => {
    let temp_v = "";
    let temp_v2 = "";
    let temp_v3 = "";
    let temp_v4 = "";
    let temp_v5 = "";
    if (v && v !== null) temp_v = v;
    if (v2 && v2 !== null) temp_v2 = v2;
    if (v3 && v3 !== null) temp_v3 = v3;
    if (v4 && v4 !== null) temp_v4 = v4;
    if (v5 && v5 !== null) temp_v5 = v5;
    let str = "";
    if (dv) str = str + " " + temp_v;
    if (dv2) str = str + " " + temp_v2;
    if (dv3) str = str + " " + temp_v3;
    if (dv4) str = str + " " + temp_v4;
    if (dv5) str = str + " " + temp_v5;
    return str;
  };

  const [currentObjMappingIdState, setCurrentObjMappingIdState] =
    useState(null);

  // useEffect(() => {
  //   if (currentObjMappingIdState) {
  //     console.log(currentObjMappingIdState, "currentObjMappingIdState");
  //   }
  // }, [currentObjMappingIdState]);

  useEffect(() => {
    if (allWorkedFormDataObj) {
      let tempAllState = [];
      allWorkedFormDataObj.map((form) => {
        // console.log(form?.FieldType, "form.FieldType",form);
        if (
          form?.FieldType !== "checkbox"
          // form.FieldType !== "signature" &&
          // form.FieldType !== "datesigned" &&
          // form.FieldType !== "initials"
        ) {
          const allSameMappingIdDivs = document.querySelectorAll(
            `.fieldBlock-text-content-mappingid-${form?.mappingid}`
          );
          let allSameMappingIdData = [];
          docs.map((d) =>
            d?.formValue?.data?.map((v) => {
              if (
                v.mappingid &&
                v.mappingid === form?.mappingid &&
                v.f_fieldtype !== "signature" &&
                v.f_fieldtype !== "datesigned" &&
                v.f_fieldtype !== "initials"
              )
                allSameMappingIdData.push(...v?.formValuesDisplayed?.data);
            })
          );

          // / TODO mappingid
          let savedValuesObj = {
            Value: null,
            Value_f2: null,
            Value_f3: null,
            Value_f4: null,
            Value_f5: null,
          };
          allSameMappingIdData?.map((t) => {
            if (t?.Value) savedValuesObj.Value = t?.Value;
            if (t?.Value_f2) savedValuesObj.Value_f2 = t?.Value_f2;
            if (t?.Value_f3) savedValuesObj.Value_f3 = t?.Value_f3;
            if (t?.Value_f4) savedValuesObj.Value_f4 = t?.Value_f4;
            if (t?.Value_f5) savedValuesObj.Value_f5 = t?.Value_f5;
          });
          allSameMappingIdData?.map((f) => {
            //TEXTAREA
            if (form?.FieldType === "textarea") {
              let fTextarea = document.getElementById(
                `fieldBlock-textarea-content-${f?.FormValuesID}-displayed-${f?.FormValuesDisplayedID}`
              );
              fTextarea.value = form?.Value;
            }
            //CURRENCY
            if (form?.FieldType === "currency") {
              let fInput = document.getElementById(
                `fieldBlock-input-content-${f?.FormValuesID}-displayed-${f?.FormValuesDisplayedID}`
              );
              fInput.value = form?.Value;
            }

            //NOT CURRENCY
            if (form?.FieldType !== "currency") {
              let fDiv = document.getElementById(
                `fieldBlock-text-content-${f?.FormValuesID}-displayed-${f?.FormValuesDisplayedID}`
              );
              // console.log(
              //   fDiv,
              //   "fDiv",
              //   `fieldBlock-text-content-${f?.FormValuesID}-displayed-${f?.FormValuesDisplayedID}`
              // );
              // seting justify-content
              let judgeAl = f?.txtjustified;
              if (judgeAl === "l") judgeAl = "fieldBlock-text-content-j-l";
              if (judgeAl === "r") judgeAl = "fieldBlock-text-content-j-r";
              if (judgeAl === "c") judgeAl = "fieldBlock-text-content-j-c";
              if (fDiv) fDiv.classList.add(judgeAl);
              ///
              const objMappingId = {
                FormID: form?.FormID,
                ContractDataID: null,
                FormValuesID: f?.FormValuesID,
                // Value: f?.Display_All
                //   ? form?.Value
                //   : f?.Display_f_value
                //   ? form?.Value
                //   : null,
                Value: form?.Value,
                // Value_f2: f?.Display_All
                //   ? form?.Value_f2
                //   : f?.Display_f2_value
                //   ? form?.Value_f2
                //   : null,
                Value_f2: form?.Value_f2,
                // Value_f3: f?.Display_All
                //   ? form?.Value_f3
                //   : f?.Display_f3_value
                //   ? form?.Value_f3
                //   : null,
                Value_f3: form?.Value_f3,
                // Value_f4: f?.Display_All
                //   ? form?.Value_f4
                //   : f?.Display_f4_value
                //   ? form?.Value_f4
                //   : null,
                Value_f4: form?.Value_f4,
                // Value_f5: f?.Display_All
                //   ? form?.Value_f5
                //   : f?.Display_f5_value
                //   ? form?.Value_f5
                //   : null,
                Value_f5: form?.Value_f5,
                FieldType: form.FieldType,
                special_action: form.special_action,
              };

              const allObjMappingId = {
                FormID: form?.FormID,
                ContractDataID: null,
                FormValuesID: f?.FormValuesID,
                Value: f?.Display_All
                  ? form?.Value
                  : f?.Display_f_value
                  ? form?.Value
                  : null,
                Value_f2: f?.Display_All
                  ? form?.Value_f2
                  : f?.Display_f2_value
                  ? form?.Value_f2
                  : null,
                Value_f3: f?.Display_All
                  ? form?.Value_f3
                  : f?.Display_f3_value
                  ? form?.Value_f3
                  : null,
                Value_f4: f?.Display_All
                  ? form?.Value_f4
                  : f?.Display_f4_value
                  ? form?.Value_f4
                  : null,
                Value_f5: f?.Display_All
                  ? form?.Value_f5
                  : f?.Display_f5_value
                  ? form?.Value_f5
                  : null,
                FieldType: form.FieldType,
                special_action: form.special_action,
                fieldData: form?.fieldData,
                mappingid: form?.mappingid,
              };
              // console.log(allObjMappingId, "objMappingId");
              dispatch(addFormsData(objMappingId));
              //maybe I should to remove it:
              // setCurrentObjMappingIdState(allObjMappingId);
              tempAllState.push(allObjMappingId);
              // dispatch(addAllWorkFormsData(allObjMappingId));

              if (fDiv) {
                if (f.Display_All === true) {
                  fDiv.textContent = toAddressFormatt(
                    form?.Value,
                    form?.Value_f2,
                    form?.Value_f3,
                    form?.Value_f4,
                    form?.Value_f5
                  );
                } else {
                  fDiv.textContent = toAddressFormattSomeFields(
                    form?.Value,
                    form?.Value_f2,
                    form?.Value_f3,
                    form?.Value_f4,
                    form?.Value_f5,
                    f?.Display_f_value,
                    f?.Display_f2_value,
                    f?.Display_f3_value,
                    f?.Display_f4_value,
                    f?.Display_f5_value
                  );
                }
              }
            }
          });

          form?.fieldData?.map((f) => {
            let fDiv = document.getElementById(
              `fieldBlock-text-content-${f?.FormValuesID}-displayed-${f?.FormValuesDisplayedID}`
            );
            if (fDiv) {
              if (f.Display_All === true) {
                fDiv.textContent = toAddressFormatt(
                  form?.Value,
                  form?.Value_f2,
                  form?.Value_f3,
                  form?.Value_f4,
                  form?.Value_f5
                );
              } else {
                fDiv.textContent = toAddressFormattSomeFields(
                  form?.Value,
                  form?.Value_f2,
                  form?.Value_f3,
                  form?.Value_f4,
                  form?.Value_f5,
                  f?.Display_f_value,
                  f?.Display_f2_value,
                  f?.Display_f3_value,
                  f?.Display_f4_value,
                  f?.Display_f5_value
                );
              }
            }
          });
        }
      });

      if (multipleFormData?.length === 0) {
        setCurrentObjMappingIdState(null);
      } else {
        setCurrentObjMappingIdState([...tempAllState]);
      }
    }
  }, [
    allWorkedFormDataObj,
    removeForm,
    isDragReorder,
    multipleFormData?.length,
  ]);

  useEffect(() => {
    if (currentPage && currentItem && currentPage.id === currentItem.id) {
      setTimeout(
        () =>
          scrollToSmoothly(
            `#pdf-image-${currentPage?.formNum}-page${currentPage.page}-id-${currentPage?.id}`.replace(
              /\s/g,
              ""
            ),
            currentPage,
            prevPdf,
            setPrevPdf
          ),
        500
      );
    }
  }, [currentItem, currentPage]);

  const createPDF = (removeForm, formDataObj) => {
    const pageContainer = document.getElementById("RightMainBlock-wrapper");
    const rootTempInputWrapperArray = [];
    let ind = 0;
    for (let doc of docs) {
      ind++;
      for (let j = 0; j < doc.TotalPages; j++) {
        let pageNumber = j + 1;
        // actualPageNumber++;
        let imageDiv = document.createElement("div");
        let image = document.createElement("img");

        /////

        if (screenWidth[0] <= 768) {
          let width = (window.innerWidth - 60) / 816;
          imageDiv.style.transform = `scale(${width})`;
          if (ind === 1) {
            imageDiv.style.marginTop = `-${(1056 - 1056 * width) / 2 - 10}px`;
          } else {
            imageDiv.style.marginTop = `-${1056 - 1056 * width}px`;
          }
        }

        imageDiv.classList.add(
          "forms-pdf-viewer",
          "image",
          "page-dimms",
          `pdf-image-totalpage${j + 1}`
        );
        imageDiv.id =
          `pdf-image-${doc?.FormNum}-page${pageNumber}-id-${doc?.id}`.replace(
            /\s/g,
            ""
          ); //"pdf-image_page"+pageNumber
        ///

        let pageNumberPDF = "";
        if (pageNumber > 9) {
          pageNumberPDF = "-0000" + pageNumber + ".png";
        } else {
          pageNumberPDF = "-00000" + pageNumber + ".png";
        }

        if (doc?.FormFileName) {
          image.src = `https://realtytexas.azureedge.net/assets/forms/png/${doc?.FormFileName.replace(
            ".pdf",
            ""
          )}${pageNumberPDF}`;
          // image.src = doc?.file_url;
        }
        image.style = `height: ${pageHeightPT}pt; width: ${pageWidthPT}pt;`;
        imageDiv.appendChild(image);
        ///
        let rootTitleDiv = document.createElement("div");
        const rootTitle = createRoot(rootTitleDiv);
        rootTitle.render(<BottomTitleBlock doc={doc} userData={userData} />);
        imageDiv.appendChild(rootTitleDiv);
        /////// handleTabPress FUNCTIONALITY!!!! START! SECOND PART
        let tempInputWrapper = doc?.formValue?.data.filter(
          (f) =>
            f.f_fieldtype !== "signature" &&
            f.f_fieldtype !== "datesigned" &&
            f.f_fieldtype !== "initials" &&
            f.f_popup !== true &&
            f.formValuesDisplayed.data.length === 1
        );

        let tempInputWrapper0 = doc?.formValue?.data.filter(
          (f) =>
            f.f_fieldtype !== "signature" &&
            f.f_fieldtype !== "datesigned" &&
            f.f_fieldtype !== "initials" &&
            f.f_popup !== true &&
            f.formValuesDisplayed.data.length > 1
        );
        let tempInputWrapper0_0 = [];
        const tempInputWrapper0_1 = tempInputWrapper0.map((f) => {
          f.formValuesDisplayed.data.map((k) => {
            let temp0 = {
              formValuesDisplayed: {
                data: [k],
              },
            };
            let temp = { ...f, ...temp0 };
            tempInputWrapper0_0.push(temp);
          });
        });
        // console.log(
        //   tempInputWrapper0,
        //   "tempInputWrapper0",
        //   tempInputWrapper0_0
        // );

        let tempInputWrapper1 = [
          ...tempInputWrapper0_0,
          ...tempInputWrapper,
        ].filter((f) => f.formValuesDisplayed.data[0].DisplayOnPage === j + 1);

        let tempInputWrapper2 = tempInputWrapper1.sort(function (a, b) {
          if (
            Math.ceil(a?.formValuesDisplayed?.data[0].UserSpaceY) !==
            Math.ceil(b?.formValuesDisplayed?.data[0].UserSpaceY)
            //   ||
            // (Math.ceil(a?.formValuesDisplayed?.data[0].UserSpaceY) -
            //   Math.ceil(b?.formValuesDisplayed?.data[0].UserSpaceY) <
            //   6 &&
            //   Math.ceil(a?.formValuesDisplayed?.data[0].UserSpaceY) -
            //     Math.ceil(b?.formValuesDisplayed?.data[0].UserSpaceY) >
            //     -6)
          ) {
            return (
              Math.ceil(a?.formValuesDisplayed?.data[0].UserSpaceY) -
              Math.ceil(b?.formValuesDisplayed?.data[0].UserSpaceY)
            );
          } else {
            return (
              Math.ceil(a?.formValuesDisplayed?.data[0].UserSpaceX) -
              Math.ceil(b?.formValuesDisplayed?.data[0].UserSpaceX)
            );
          }
        });

        rootTempInputWrapperArray.push(...tempInputWrapper2);
        /////// handleTabPress FUNCTIONALITY!!!! START! SECOND PART

        doc?.formValue?.data?.map((data, i, data_array) => {
          if (
            data?.f_fieldtype !== "signature" &&
            data?.f_fieldtype !== "datesigned" &&
            data?.f_fieldtype !== "initials"
          ) {
            data?.formValuesDisplayed?.data?.map((f, index) => {
              let defaultHeight;
              if (data?.f_fieldtype === "checkbox") defaultHeight = 10;
              if (data?.f_fieldtype === "textfield") defaultHeight = 14;
              if (data?.f_fieldtype === "datetime") defaultHeight = 14;
              if (data?.f_fieldtype === "currency") defaultHeight = 14;
              if (data?.f_fieldtype === "number") defaultHeight = 14;

              if (f?.DisplayOnPage === +pageNumber) {
                let fieldsDiv = document.createElement("div");
                fieldsDiv.classList.add(
                  `fieldBlock-${f?.FormValuesID}`,
                  "fieldwrapper",
                  `pdf-fields-${doc?.id}-page${pageNumber}`
                );
                fieldsDiv.id = `pdf-fields-${doc?.id}-page${pageNumber}-index-${i}`;
                fieldsDiv.dataset.dataPageNo = pageNumber;
                let xCoord = parseFloat(f?.UserSpaceX) * 0.888328075709779;
                let yCoord = parseFloat(f?.UserSpaceY) * 0.888328075709779;
                let widthX = parseFloat(f?.WidthX) * 0.888328075709779;
                let heightX = parseFloat(f?.HeightX) * 0.888328075709779;
                fieldsDiv.style = `height: ${
                  heightX ? heightX : defaultHeight
                }px; width: ${
                  widthX ? widthX : 0
                }px; left: ${xCoord}px; top: ${yCoord}px; zIndex:1000;`;
                imageDiv.appendChild(fieldsDiv);

                let rootDiv = document.createElement("div");
                rootDiv.id = `fieldBlock-${f?.FormValuesID}`;
                const root = createRoot(rootDiv);
                root.render(
                  <FieldBlock
                    dispatch={dispatch}
                    allFromData={data_array?.filter(
                      (d) => d.FormID === data.FormID
                    )}
                    doc={doc}
                    pageNumber={pageNumber}
                    field={f}
                    fieldType={data?.f_fieldtype}
                    params={{
                      height: heightX ? heightX : defaultHeight,
                      width: widthX ? widthX : 0,
                    }}
                    isPopUp={data?.f_popup}
                    setCurrentFieldData={setCurrentFieldData}
                    mappingid={data?.mappingid}
                    formDataObj={formDataObj ? formDataObj : []}
                    isCheckboxChecked={isCheckboxChecked}
                    setIsCheckboxChecked={setIsCheckboxChecked}
                  />
                );
                fieldsDiv.appendChild(rootDiv);
              }
            });
          }
        });

        pageContainer.appendChild(imageDiv);
      }
    }
    /////// handleTabPress FUNCTIONALITY!!!! START! THIRD PART
    setInputWrappers([...rootTempInputWrapperArray]);
    /////// handleTabPress FUNCTIONALITY!!!! END! THIRD PART
    if (!removeForm) {
      formDataObj?.map((obj) => {
        if (obj.FieldType === "checkbox") {
          const checkBoxDiv = document.getElementById(
            `fieldBlock-${obj?.FormValuesID}`
          );
          const checked = document.createAttribute("checked");
          checked.value = "true";
          if (checkBoxDiv) {
            checkBoxDiv.setAttributeNode(checked);
          }
        }
      });
    }
  };

  const [formsAdded, setFormsAdded] = useState(false);

  useEffect(() => {
    setFormsAdded(!formsAdded);
  }, [multipleFormData?.length]);

  useEffect(() => {
    let element = document.getElementById("RightMainBlock-wrapper");
    element.textContent = "";
    createPDF(removeForm, formDataObj);
    // }, [removeForm, isDragReorder, formsAdded, isCheckboxChecked]); //SHOULD REMOVE isCheckboxChecked from HERE!
  }, [removeForm, isDragReorder, formsAdded, isCheckboxChecked]);

  useEffect(() => {
    let element = document.getElementById("RightMainBlock-wrapper");
    element.textContent = "";
    createPDF(removeForm, formDataObj);
  }, [screenWidth[0]]);

  ///SCROLLING!!!!
  const [scrolling, setScrolling] = useState(false);
  const [scrollTimeout, setScrollTimeout] = useState(null);
  const [visibleDivId, setVisibleDivId] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      setScrolling(true);
      clearTimeout(scrollTimeout);

      const timeout = setTimeout(() => {
        setScrolling(false);
      }, 150); // Adjust the timeout duration as needed

      setScrollTimeout(timeout);
    };
    let div = document.querySelectorAll(".RightMainBlock-wrapper")[0];
    div.addEventListener("scroll", handleScroll);

    return () => {
      div.removeEventListener("scroll", handleScroll);
      clearTimeout(scrollTimeout);
    };
  }, [scrollTimeout]);

  useEffect(() => {
    let rootDiv = document.querySelectorAll(".RightMainBlock-wrapper")[0];
    if (!scrolling) {
      // Find the div on the screen
      const divs = document.querySelectorAll(".forms-pdf-viewer");
      divs.forEach((div) => {
        const rect = div.getBoundingClientRect();
        // if (id === 1) console.log({ top: rect.top, bot: rect.bottom }, "fffff");
        if (rect.top < window.innerHeight) {
          //TODO !!!!!!
          setVisibleDivId(div.id.slice(10));
          //

          let leftDiv = document.getElementById(
            `LeftTitleBlock-list-item_${div.id.slice(10)}`.replaceAll(/\s/g, "")
          );

          const divs = document.querySelectorAll(".LeftTitleBlock-list-item");
          if (divs.length > 0) {
            divs.forEach((div) => {
              div.style.backgroundColor = colors.white;
              div.style.color = colors.blue;
            });
          }
          leftDiv.style.backgroundColor = colors.blue;
          leftDiv.style.color = colors.white;
          const currentPage = div.id.slice(10).split("-")[
            div.id.slice(10).split("-").length - 1
          ];
          const currentName = div.id.slice(10).replace("-" + currentPage, "");
          setRightHeaderContent(currentName);
        }
      });
    }
    // if (scrolling) {
    //   setCurrentPage(null);
    // }
  }, [scrolling]);

  //changing the right header

  const setRightHeaderContent = (value) => {
    const apiResult = multipleFormData.filter(
      (item) => item.FormNum === value
    )[0];
    setCurrentItem(apiResult);
  };

  const { isFeildFormOpened } = useSelector((state) => state.isFormOpened);

  return (
    <>
      {isFeildFormOpened && (
        <FieldForm
          data={currentFieldData}
          savedData={formDataObj}
          allSavedData={currentObjMappingIdState}
        />
      )}
      <div
        id="RightMainBlock-wrapper"
        className={
          leftBarOpened
            ? "RightMainBlock-wrapper RightMainBlock-wrapper-closed"
            : isNarrowToggle
            ? "RightMainBlock-wrapper RightMainBlock-wrapper-opened RightMainBlock-wrapper-narrow"
            : "RightMainBlock-wrapper RightMainBlock-wrapper-opened RightMainBlock-wrapper-wide"
        }
      >
        {/* Right Side */}
      </div>
    </>
  );
}

export default RightMainBlock;
