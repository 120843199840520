const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const toIsoFormat = (currentDate, pos = null) => {
  const year = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth().toString();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  const day = currentDate.getDate().toString().padStart(2, "0");
  if (!pos) {
    return `${year}-${month}-${day}`;
  } else {
    if (pos === "day") {
      return `${day}`;
    }
    if (pos === "month") {
      return `${month}`;
    }
    if (pos === "year") {
      return `${year}`;
    }
    if (pos === "year-YY") {
      return `${year}`.slice(-2);
    }
    if (pos === "month-MMMM") {
      // console.log(pos);
      return `${months[currentMonth]}`;
    }
  }
};
