import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { BaseButton, BaseInput } from "../../ui";
import "./Login.css";
import "./responsive.css";
import * as Yup from "yup";
import { useAuth } from "../../hooks";
import backgroundImg from "../../images/real-forms-logo-color-ni.png";
import axios from "axios";
import { PopUpMessage } from "..";

const LoginSchema = Yup.object().shape({
  password: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
});

function Login() {
  const { login, setToken } = useAuth();
  const [isError, setIsError] = useState(false);
  const [isErrorText, setIsErrorText] = useState("");
  const [isImgLoaded, setIsImgLoaded] = useState(false);

  const handleLogin = async (value) => {
    setIsError(false);
    const body = value;
    axios
      .post(`https://api.realtytexas.net/v1/clients/mobile/login`, body)
      .then((res) => {
        if (res?.data?.access_token) {
          login(value.email);
          setToken({
            access_token: res?.data?.access_token,
            refresh_token: res?.data?.refresh_token,
            expires_in: res?.data?.expires_in,
          });
        }
      })
      .catch(function (error) {
        console.log(error?.response?.data?.message);
        setIsError(true);
        setIsErrorText(error?.response?.data?.message);
      });
  };

  const onImgLoadedHandler = () => {
    setIsImgLoaded(true);
  };

  return (
    <div className="Login-wrapper" style={{ opacity: isImgLoaded ? 1 : 0 }}>
      <div className="Login-wrapper-box">
        <div className="Login-title-wrapper">
          <img src={backgroundImg} onLoad={onImgLoadedHandler} />
          <div className="Login-title-context">
            Welcome to our forms system.
          </div>
        </div>
        <div className="Login-content-wrapper">
          <Formik
            initialValues={{ email: "", password: "" }}
            validationSchema={LoginSchema}
            onSubmit={(values, { setSubmitting }) => {
              if (!isError) {
                handleLogin({
                  email: values.email,
                  password: values.password,
                });
              }
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit} autoComplete="off">
                <div className="Login-content-form-wrapper">
                  <BaseInput
                    type="email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    placeholder="User Name"
                    required={errors.email && touched.email && errors.email}
                    // label={"Login"}
                  />

                  <div className="Login-content-form-error-email">
                    {errors.email && touched.email && errors.email}
                  </div>

                  <BaseInput
                    type="password"
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    placeholder="Password"
                    required={
                      errors.password && touched.password && errors.password
                    }
                    // label={"Password"}
                  />
                  <div className="Login-content-form-error-password">
                    {errors.password && touched.password && errors.password}
                  </div>
                  <BaseButton type="submit" disabled={isSubmitting}>
                    LOGIN
                  </BaseButton>
                </div>
              </form>
            )}
          </Formik>
        </div>
        <div className="Login-bottom-text-wrapper">
          Powered by Realty Texas, LLC
        </div>
        {isError && (
          <PopUpMessage
            isVisible={isError}
            setIsVisible={setIsError}
            content={isErrorText}
          />
        )}
      </div>
    </div>
  );
}

export default Login;
