import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  form: null,
};

export const formToRemoveSlice = createSlice({
  name: "formToRemove",
  initialState,
  reducers: {
    setFormToRemove(state, actions) {
      state.form = actions.payload;
    },
  },
});

export default formToRemoveSlice.reducer;
