import "./App.css";
import { setupStore } from "./store/store";
import { Provider } from "react-redux";
import { MainPage } from "./pages";
import { useEffect, useState } from "react";
import { useApp } from "./context/AppContext";
import { LoginForm } from "./modules";
import { useAuth } from "./hooks";
import { useIsAccessToken } from "./context/IsAccessTokenContext";

function App({ options }) {
  const { triggerRerender } = useApp();
  const [currentToken, setCurrentToken] = useState(options?.accessToken);

  const { tokenExpired, setTokenExpired } = useIsAccessToken();

  const store = setupStore();

  const { user, userToken, userRefreshToken } = useAuth();

  useEffect(() => {
    if (currentToken || (user && userToken && userRefreshToken)) {
      setTokenExpired(true);
    }
  }, [userToken, user, currentToken, userRefreshToken]);

  const tokenExpiredHandler = () => {
    triggerRerender();
    // console.log("RTFormsSystemTokenExpired");
  };

  useEffect(() => {
    document.addEventListener("RTFormsSystemTokenExpired", tokenExpiredHandler);
    return () => {
      document.removeEventListener(
        "RTFormsSystemTokenExpired",
        tokenExpiredHandler
      );
    };
  }, []);

  return (
    <Provider store={store}>
      {!tokenExpired && <LoginForm />}
      {tokenExpired && (
        <MainPage
          accessToken={currentToken ? currentToken : userToken}
          options={options}
          setCurrentToken={setCurrentToken}
          currentToken={currentToken}
        />
      )}
    </Provider>
  );
}

export default App;
