import React, { useEffect, useState } from "react";
import { colors } from "../../constants/colors";
import { CalendarIcon, CloseIcon } from "../../constants/icons";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import "./DatePicker.css";

function DatePicker({
  options,
  onChange,
  datePickerRef,
  flatpickrInstance,
  initialValue = null,
}) {
  useEffect(() => {
    // Initialize flatpickr on the input element
    flatpickrInstance.current = flatpickr(datePickerRef.current, {
      ...options,
      onChange: onChange, // Pass the onChange handler if provided
    });

    // Set initial value if provided
    if (initialValue) {
      flatpickrInstance.current.setDate(initialValue);
    }

    // Cleanup function to destroy flatpickr instance on component unmount
    return () => {
      flatpickrInstance.current.destroy();
    };
  }, [options, onChange, initialValue]);

  const clearDate = () => {
    if (flatpickrInstance.current) {
      flatpickrInstance.current.clear();
    }
  };

  return (
    <div className="DatePicker-block-wrapper" ref={datePickerRef}>
      <div>
        <CalendarIcon height={22} width={22} />
      </div>
    </div>
  );
}

export default DatePicker;
