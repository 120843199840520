import React, { useEffect, useState } from "react";
import "./BottomTitleBlock.css";

function BottomTitleBlock({ doc, userData }) {
  let isUserData = false;
  let VanityNumber = "";
  let AgenteMail = "";
  let AgentChimeName = "";
  let AgentPhone = "";
  let noUserContent;
  let content;

  if (userData?.data?.agent?.data && userData?.data?.agent?.data?.length > 0) {
    isUserData = true;
  }
  const title = `${doc?.FormNum ? doc.FormNum : ""} ${
    doc?.FormName ? doc.FormName : ""
  }`;
  const FormID = doc?.formValue?.data[0]?.FormID;

  if (isUserData && userData?.data?.agent) {
    AgentChimeName = userData?.data?.agent?.data[0]?.AgentChimeName
      ? `| ${userData?.data?.agent?.data[0]?.AgentChimeName}`
      : "";
    VanityNumber = userData?.data?.agent?.data[0]?.vanity_phone_standard
      ? `| ${userData?.data?.agent?.data[0]?.vanity_phone_standard}`
      : "";
    AgenteMail = userData?.data?.agent?.data[0]?.AgenteMail
      ? `| ${userData?.data?.agent?.data[0]?.AgenteMail}`
      : "";
    AgentPhone = userData?.data?.agent?.data[0]?.bizphone_standard
      ? `| ${userData?.data?.agent?.data[0]?.bizphone_standard}`
      : "";

    content = `Realty Texas ${AgentChimeName} ${VanityNumber} ${AgentPhone} ${AgenteMail} | ${FormID}`;
  }
  noUserContent = `Realty Texas | (800) 660-1022 | sales@realtytexas.com | ${FormID}`;

  return (
    <div className="BottomTitleBlock-wrapper">
      {isUserData && content ? content : noUserContent}
    </div>
  );
}

export default BottomTitleBlock;
