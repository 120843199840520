export function calculateTokenExpirationTime(tokenExpirationInSeconds) {
  const currentTimeInSeconds = Math.floor(Date.now() / 1000);
  const expirationTimeInSeconds =
    currentTimeInSeconds + tokenExpirationInSeconds;
  const expirationDate = new Date(expirationTimeInSeconds * 1000);
  return expirationDate;
}

export function isTokenExpired(tokenExpirationTime) {
  //   const currentTime = new Date().getTime() / 1000 + 86286;
  const currentTime = new Date().getTime() / 1000;
  //   console.log(currentTime - tokenExpirationTime);
  return currentTime > tokenExpirationTime;
}
