import { colors } from "../constants/colors";

export function waitForElm(selector) {
  return new Promise((resolve) => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver((mutations) => {
      if (document.querySelector(selector)) {
        observer.disconnect();
        resolve(document.querySelector(selector));
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
}

export const scrollToSmoothly = (div, page, prevPdf, setPrevPdf) => {
  waitForElm(div).then((element) => {
    // console.log("Element is ready");
    // let allLeftItems = document.getElementsByClassName(
    //   "LeftTitleBlock-list-item"
    // );

    let leftDiv = document.getElementById(
      `LeftTitleBlock-list-item_${page?.formNum}-page${page.page}-id-${page.id}`.replaceAll(
        /\s/g,
        ""
      )
    );
    setPrevPdf(leftDiv);
    leftDiv.style.backgroundColor = colors.blue;
    leftDiv.style.color = colors.white;
    if (prevPdf) {
      prevPdf.style.backgroundColor = colors.white;
      prevPdf.style.color = colors.blue;
    }
    // const divs = document.querySelectorAll(".LeftTitleBlock-list-item");
    // if (divs.length > 0) {
    //   divs.forEach((div) => {
    //     div.style.backgroundColor = colors.white;
    //     div.style.color = colors.mainBg;
    //   });
    // }

    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  });
};

export function observeElements(className, callback) {
  const elements = document.querySelectorAll(`.${className}`);

  const observerCallback = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        callback(entry.target, true); // Element is visible
      } else {
        callback(entry.target, false); // Element is hidden
      }
    });
  };

  const observer = new IntersectionObserver(observerCallback);
  setTimeout(
    elements.forEach((element) => {
      observer.observe(element);
    }),
    500
  );

  return () => {
    elements.forEach((element) => {
      observer.unobserve(element);
    });
  };
}
