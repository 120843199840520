import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  formsReduced: [],
};

export const formsReducedSlice = createSlice({
  name: "formsReduced",
  initialState,
  reducers: {
    addReducedForms(state, actions) {
      if (state.formsReduced.length > 0) {
        state.formsReduced = [
          ...state.formsReduced.filter(
            (f) => f.formId !== actions.payload.formId
          ),
          actions.payload,
        ];
      } else {
        state.formsReduced = [actions.payload];
      }
    },
    removeReducedForms(state, actions) {
      if (state.formsReduced !== 0) {
        state.formsReduced = [
          ...state.formsReduced.filter(
            (f) => f.formId !== actions.payload.formId
          ),
        ];
      }
    },
    resetReducedForms(state) {
      state.formsReduced = [];
    },
  },
});

export default formsReducedSlice.reducer;
